.header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    color: white;
}

.list-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    color: white;
    font-weight: 400;
}

.list-header:first-child {
    margin-right: 16px;
}

.history-section {
    /*border: 1px solid #fff;*/
    border-radius: 4px;
    /*padding: 4px;*/
    /* background-color: #0f86ad !important; */
    display: flex;
    max-height: 605px;
    overflow: auto;
}

.last-ten-label {
    background-color: #42b1d6;
    border-radius: 7px;
    color: #ffffff;
    margin-bottom: 10px;
    padding: 0 4px 4px 0;
}

.label-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.label-icon-right {
    padding: 4px 8px 0 0;
}

.ph-label-text {
    padding-top: 6px;
}

.label-icon-left {
    margin-right: 8px;
    color: #42b1d6;
}

div.input-style > input {
    color: black !important;
    padding-left: 4px !important;
}

.find-tag {
    height: 21px;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
    border-radius: 3px;
    background: #f78b56!important;
    color: white;
    margin-bottom: 0 !important;
    font-size: 10px;
}

.history-list-label {
    color: #000000;
    border: 2px solid #000000;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.history-container-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-items: center;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
}
.history-container-row div {
    margin-right: 0px;
}
.history-container-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    color: #000000;
    margin-bottom: 4px;
    border-radius: 4px;
    padding: 4px;
}

.invalid-location {
    opacity: 0.8;
}

.date {
    font-size: 12px;
    margin-left: 32px;
}

.date-no-indent {
    margin-left: 0;
    margin-bottom: 0;
    font-size: 14px;
}

.place-name {
    margin-bottom: 0;
}

.timer-header {
    margin-left: 4px;
    margin-right: 4px;
    font-width: 600;
    color: white;
}

.small-font {
    font-size: 12px;
    padding-top: 2px;
    color: white;
}

.large-font {
    font-size: 22px;
}
