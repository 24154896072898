.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white !important;
    background-color: #139ecd !important;
}

.nav-tabs .nav-link, .nav-tabs {
    color: lightgray;
}

.nav-tabs {
    border-bottom: none !important;
}

.nav-tabs .nav-link:hover{
    color: #fff !important;
    background-color: #f78b56 !important;
    border-color: #f78b56 !important;
}

.zone-creation-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /*border-top: thin solid white;*/
    margin-top: -16px;
    padding-top: 10px;
}

.zone-help {
    color: white;
    padding-top: 10px;
}

.zone-button {
    width: 75%;
}
