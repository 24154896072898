.batteryDate {
    color: white;
    font-size: 10px;
    font-weight: 400;
}

.white {
    color: white !important;
}

.batteryContainer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.batteryOuter {
    border: 1px solid white;
    padding: 1px;
    width: 100%;
    height: 9px;
}

.batteryBump {
    /*border-radius: 2px;*/
    background-color: white;
    margin: 0px;
    width: 3px;
    height: 5px;
}

#batteryLevel {
    border-radius: 2px;
    background-color: #086585;
    height: 6px;
}
