@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(10deg);
    }
    30% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    60% {
        transform: rotate(5deg);
    }
    70% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes sonar {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

body {
    font-size: 0.9rem;
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand>a,
.sidebar-wrapper .sidebar-dropdown>a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}


/*----------------page-wrapper----------------*/

.page-wrapper {
    height: 100vh;
}

.page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}

.page-wrapper .theme.chiller-theme {
    background: #1e2229;
}


/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
}

@media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
        padding-left: 225px;
    }
}


/*----------------show sidebar button----------------*/

#show-sidebar {
    /* position: fixed;
    left: 0;
    top: 10px; */
    border-radius: 4px;
    width: 35px;
    transition-delay: 0.3s;
    margin-top: 7px;
}
#show-sidebar:focus{
    box-shadow: none;
    outline: none;
}
.page-wrapper.toggled #show-sidebar {
    left: -40px;
    margin-top: 7px;
}


/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
    width: 65px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
}
.sidebar-wrapper.show{
    width: 225px!important;
}

.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-wrapper a {
    text-decoration: none;
}


/*----------------sidebar-content----------------*/

.sidebar-content {
    /* max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow-y: auto; */
    position: relative;
}

.sidebar-content.desktop {
    overflow-y: hidden;
}


/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
    padding: 14px 14px;
    display: flex;
    align-items: center;
    position: relative;
}

.sidebar-wrapper .sidebar-brand>a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    width: 90%;
    display: block;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
}

.sidebar-wrapper .sidebar-brand>a>img {
    /* width: 60%; */
    display: block;
    margin: 0 auto;
}


/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
    float: left;
    width: 45px;
    height: 45px;
    border-radius: 6px;
    margin-right: 15px;
    overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
    float: left;
}

.sidebar-wrapper .sidebar-header .user-info>span {
    display: block;
    margin-bottom: 5px;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
    color: #5cb85c;
}


/*-----------------------sidebar-search------------------------*/


/* .sidebar-wrapper .sidebar-search>div {
    padding: 10px 20px;
} */


/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li {
    width: 100%;
    padding: 10px 0px 0 10px;
    /* margin-bottom: 10px; */
}

.sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 1px;
    /* border-radius: 30px 0 0 30px; */
}



.sidebar-wrapper .sidebar-menu ul li a:hover,
.sidebar-wrapper .sidebar-menu ul li a.active {
    color: #fff !important;
    background-color: #149ccc;
}

.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a span.icon {
    margin-right: 10px;
    font-size: 12px;
    width: 25px;
    height: 25px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    float: left;
    margin-right: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 300ms ease-in-out;
}

.sidebar-wrapper ul li a .icon.dashboard {
    background-image: url(../assets/admin-icon/dashboard_blue.png);
}

.sidebar-wrapper ul li:hover a .icon.dashboard,
.sidebar-wrapper ul li a.active .icon.dashboard {
    background-image: url(../assets/admin-icon/dashboard.png);
}
.sidebar-wrapper ul li a .icon.user-list {
    background-image: url(../assets/admin-icon/userlist_blue.png);
}

.sidebar-wrapper ul li:hover a .icon.user-list,
.sidebar-wrapper ul li a.active .icon.user-list {
    background-image: url(../assets/admin-icon/userlist.png);
}
.sidebar-wrapper ul li a .icon.iccid {
    background-image: url(../assets/admin-icon/ICCID_blue.png);
}

.sidebar-wrapper ul li:hover a .icon.iccid,
.sidebar-wrapper ul li  a.active .icon.iccid {
    background-image: url(../assets/admin-icon/ICCID.png);
}
.sidebar-wrapper ul li a .icon.sub-plan {
    background-image: url(../assets/admin-icon/plan_blue.png);
}

.sidebar-wrapper ul li:hover a .icon.sub-plan,
.sidebar-wrapper ul li a.active  .icon.sub-plan {
    background-image: url(../assets/admin-icon/plan.png);
}
.sidebar-wrapper ul li a .icon.payment {
    background-image: url(../assets/admin-icon/payment_blue.png);
}

.sidebar-wrapper ul li:hover a .icon.payment,
.sidebar-wrapper ul li  a.active .icon.payment {
    background-image: url(../assets/admin-icon/payment.png);
}
.sidebar-wrapper ul li a .icon.pet-setting {
    background-image: url(../assets/admin-icon/pet_setting_blue.png);
}

.sidebar-wrapper ul li:hover a .icon.pet-setting,
.sidebar-wrapper ul li a.active .icon.pet-setting {
    background-image: url(../assets/admin-icon/pet_setting.png);
}
.sidebar-wrapper ul li a .icon.setting {
    background-image: url(../assets/admin-icon/setting_blue.png);
}

.sidebar-wrapper ul li:hover a .icon.setting,
.sidebar-wrapper ul li a.active .icon.setting {
    background-image: url(../assets/admin-icon/setting.png);
}

.sidebar-wrapper .sidebar-menu ul li a:hover>i::before {
    display: inline-block;
    animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown>a:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 25px;
    font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
    content: "\f111";
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
    margin-right: 15px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
    display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after {
    transform: rotate(90deg);
    right: 17px;
}

.mini-sidebar ul li .nav-name{
    display: none;

}
/*--------------------------side-footer------------------------------*/

.sidebar-footer {
    position: fixed;
    bottom: 0;
    top: auto;
    width: 65px;
    /* bottom: 25px; */
    /* display: flex; */
    display: block;
    background-color: #FFF;
    color: #242447;
    font-size: 12px;
    font-weight: 600;
}
.sidebar-footer .btn-dark {
    background-color: #f38954;
    border-color: #f38954;
   
    margin-right: 15px;
}
.sidebar-wrapper.show .sidebar-footer {
    width: 225px;
}
.sidebar-wrapper .sidebar-footer {
    width: 65px;
}

/*
.sidebar-footer>a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
}

.sidebar-footer>a .notification {
    position: absolute;
    top: 0;
} */


/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 70px!important;
    /* padding-top: 20px; */
}

.page-wrapper .page-content {
    overflow-x: hidden;
}
.content-holder.show {
    padding-left: 165px!important;
}

/*------scroll bar---------------------*/

 ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}

 ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

 ::-webkit-scrollbar-thumb {
    background: #525965;
    border: 0px none #ffffff;
    border-radius: 0px;
}

 ::-webkit-scrollbar-thumb:hover {
    background: #525965;
}

 ::-webkit-scrollbar-thumb:active {
    background: #525965;
}

 ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
}

 ::-webkit-scrollbar-track:hover {
    background: transparent;
}

 ::-webkit-scrollbar-track:active {
    background: transparent;
}

 ::-webkit-scrollbar-corner {
    background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.50);
}

.chiller-theme .sidebar-wrapper .sidebar-header {
    border-top: 1px solid #017096;
    background-color: #017096;
}

.chiller-theme .sidebar-wrapper .sidebar-menu {
    border-top: 1px solid #fff;
    background-color: #fff;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role {
    color: #ffffff !important;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a {
    color: #3a3a39;
   
}

/* .chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a, */
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover {
    color: #ffffff;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: transparent;
    color: #ffffff;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}


/* .chiller-theme .sidebar-footer {
    background: #3a3f48;
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
} */