.header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    color: white;
}

.list-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    color: white;
    font-weight: 400;
}

.list-header:first-child {
    margin-right: 16px;
}

.event-section {
    border: 1px solid #fff;
    /* background-color: #0f86ad !important; */
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 10px;
}

.last-ten-label {
    background-color: #42b1d6;
    border-radius: 7px;
    color: #ffffff;
    margin-bottom: 10px;
    padding: 0 4px 4px 0;
}

.label-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.label-text {
    padding: 8px 0 0 8px;
}

.label-icon {
    padding: 8px 8px 0 0;
}


div.input-style > input {
    color: black !important;
    padding-left: 4px !important;
}

.command-error {
    font-size: 8px;
}
.cal-icon-left {
    margin-left: 8px;
    margin-top: 2px;
    color: #ffffff;
}
