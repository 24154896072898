.warning {
    color: deeppink;
    padding: 13px 20px;
    background-color: pink;
    font-weight: 600;
    text-align: center;
}

.sleep-button {
    color: #fff;
    font-size: 14px;
    padding: 8px 10px;
    background: 0 0;
    border: 0;
    border-radius: 4px;
    cursor: pointer
}

.sleep-button:hover {
    background-color: #f38954 !important;
    color: #fff;
    transition: all 300ms ease-in-out;
}
