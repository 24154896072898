@import "_reset.scss";
.h-90 {
  height: 95%!important;
}
body {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Arimo", sans-serif !important;
}
.accordion-button:focus{
  outline: none!important;
  box-shadow:none!important;

}
.plan-validity{
  font-size: 14px;
  font-weight: 500;
}
p {
  line-height: 20px;
}
.btn{
  &:focus{
    box-shadow: none;
    outline: none;
  }
}
.text-align-justify {
  text-align: justify;
}

.text-blue {
  color: #149dcd;
}

// .fa {
//   cursor: pointer !important;
//   color: #606060;
//   font-size: 14px;

//   &:hover {
//     color: #f38954 !important;
//   }
// }

// .fas {
//   cursor: pointer !important;
//   color: #606060;
//   font-size: 14px;

//   &:hover {
//     color: #f38954 !important;
//   }
// }

.find-btn {
  padding: 10px;
  //background-color: #F1F1F1 !important;
  border-radius: 5px !important;

  i {
    color: #139DCC;
  }
}

.icon-button {
  color: #606060;
  font-size: 14px;
  border: none;
  background-color: transparent;
  transition: all 300ms ease-in-out;

  &:hover {
    color: #f38954;
    transition: all 300ms ease-in-out;
  }
}

.login-bg {
  background-image: url(../images/bg-signin.jpg);
  // background-image: url(../images/bg-image.png);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  width: 100%;
  height: 100%!important;
}

.login-holder {
  background-color: #149dcd;
  height: 100vh;
  padding: 50px 70px;
  overflow-y: auto;
  //   display: flex;
  //   align-items: center;
  min-height: 100%;

  .login-container {
    border-radius: 10px;
    padding: 20px 30px;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.23);
.check-change{
  .form-check-label{
    font-size: 14px;
    margin-top: 1px;

  }
}
    .logo {
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 30px;

      img {
        display: block;
        margin: 0 auto;
        width: 40%;
      }
    }

    .panel-title {
      font-size: 20px;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .forgot-pwd {
      float: right;
      color: #fff;
      text-decoration: none;
    }

    .list-inline{
      margin-top: 40px;
      .list-inline-item{
        width: 50%;
      }
    }
  }
}

.form-group {
  margin-bottom: 15px;

  label {
    font-size: 14px;
  }

  .input-group {
    .input-group-text {
      min-width: 42px;
      max-width: 42px;
      justify-content: center;
      background-color: #ffffff;

    }

    &.white {
      .input-group-text {
        background-color: rgba(255, 255, 255, 0.9);
        border: none;

        .fa,
        .bx,
        .fa-money-bill-1,
        .fa-calendar-check,
        .fa-file-invoice-dollar {
          color: #149dcd;
        }

        .bx {
          font-size: 20px;
        }
      }

      .form-control {
        margin-left: 0px !important;
        border: none;
        color: #000;
      }
    }
  }

  .form-control {
    height: 40px;
    font-size: 14px;

    &.white {
      background-color: rgba(255, 255, 255, 0.9);
      border-color: rgba(255, 255, 255, 0.9);
      padding-left: 0;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &::placeholder {
      font-size: 12px;
    }
  }
}

.btn {
  border-radius: 6px;
  // height: 40px;
  font-size: 15px;
}

.min-width-120 {
  min-width: 120px;
}

.btn-outline-primary {
  border-color: #a2a2a2 !important;
  color: #000 !important;

  &.white {
    color: #ffffff !important;
    border-color: #ffffff !important;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #f78b56 !important;
    border-color: #f78b56 !important;
  }

}

.device-plan {

  .form-check-label {
    color: #000 !important;
    padding: 9px !important;
    border: 1px solid #000 !important;
  }

  [type="radio"]:checked+label.form-check-label {
    border: 1px solid #139DCC !important;
    color: #fff !important;
    background-color: #139DCC !important;
    border-color: #139DCC !important;
    box-shadow: 0 0 10px rgba(19, 157, 204, 0.9) !important;
  }
}

.check-change {
  .form-check-input {
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:checked {
      border: 1px solid #000 !important;
      color: #fff !important;
      background-color: #f78b56 !important;
      border-color: #f78b56 !important;
      // box-shadow: 0 0 10px rgba(247, 139, 86, 0.9) !important;

    }
  }
}

.custom-check.white {
  padding-left: 0;

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  .form-check-label {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &::after {
      content: inherit;
    }

    .plan-name {
      float: left;
      font-weight: 700;
    }

    .plan-price {
      float: right;
      font-size: 24px;
      font-weight: 500;
    }
  }

  [type="radio"]:checked+label.form-check-label {
    border-width: 1px;
    background-color: #f78b56;
    border-color: #f78b56;
    box-shadow: 0 0 10px rgba(247, 139, 86, 0.9);
  }
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ffffff !important;
  border-color: #ffffff !important;
  background-color: transparent !important;
}

/******************* Custome Select *****************/

.input-group .custom-dropdown {
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

/***************** White Dropdown *************/
.input-group.white .select2-container--default .select2-selection--single {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  background-color: rgba(255, 255, 255, 0.9);
  border: none !important;
  margin-left: 1px !important;
}

.white .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.white .select2-container--default .select2-selection--single:focus {
  border-color: rgba(255, 255, 255, 0.9) !important;
}

.propositions-blocks .custom-dropdown .select2-container {
  max-width: 230px;
  margin: 0 auto;
  display: block;
}

.custom-dropdown .select2-container {
  width: 100% !important;
  /* min-width: 170px; */
}

.select2-search__field:focus {
  outline: none !important;
  font-size: 14px !important;
}

.select2-container--default .select2-selection--single {
  border-color: #ced4da !important;
  // border-radius: 6px !important;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, .10) !important; */
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 40px !important;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000 !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  font-size: 14px !important;
  text-align: left;
  padding: 8px 30px 8px 8px !important;
}

.input-group.white .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 0 !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.custom-dropdown.text-white .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #fff !important;
}

.custom-dropdown.text-white .select2-container--default .select2-selection--single .select2-selection__arrow .fa {
  color: #fff !important;
}

.custom-dropdown.text-white .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fff !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow .fa {
  color: #000000 !important;
  font-size: 20px !important;
  position: absolute !important;
  top: 7px !important;
  right: 4px !important;
  font-weight: bold !important;
}

.select2-results__option {
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 8px 10px !important;
}

.select2-dropdown {
  border-radius: 6px !important;
  border: 1px solid #ced4da !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single:focus {
  outline: none !important;
  border-color: #86b7fe !important;
  box-shadow: none !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #666666 !important;
  font-size: 14px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px !important;
  top: 2px !important;
  right: 5px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #139dcc !important;
  color: white;
}

.select2-results .select2-results__options li:first-child {
  border-radius: 6px 6px 0 0 !important;
}

.select2-results .select2-results__options li:last-child {
  border-radius: 0 0 6px 6px !important;
}

.select2-container .select2-selection--multiple {
  min-height: 38px !important;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #e5e5e5 !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 5px !important;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 4px !important;
  line-height: 27px !important;
  font-size: 16px !important;
  color: #000000 !important;
  padding: 0px 8px 0px 3px !important;
}

.select2-container .select2-search--inline .select2-search__field::placeholder {
  color: #000000 !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: 8px !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #e5a205 1px !important;
  box-shadow: 0 0 0 0.2rem rgba(229, 162, 5, 0.25) !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #1a1a18 !important;
  border: 1px solid #1a1a18 !important;
  color: #fff !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff !important;
}

.errors {
  margin-top: 0.25rem;
  font-size: .875em;
  color: #fff;
}

.invalid-feedback {
  color: #fff !important;
}

/******************* Custome Select *****************/
.form-control.calender {
  position: relative;
  background-image: url(../images/calender-icon.png);
  background-repeat: no-repeat;
  padding-right: 30px;
  background-position: 97% center;
  background-size: 5%;
}

.form-control.watch {
  position: relative;
  background-image: url(../images/watch-icon.png);
  background-repeat: no-repeat;
  padding-right: 30px;
  background-position: 97% center;
  background-size: 5%;
}

.list-group-numbered>li {
  line-height: 28px;

  &::before {
    margin-left: -18px;
  }
}

.version-detials {
  font-size: 14px;
  color: #ffffff;
}

.view-data {
  .caption-label {
    color: #ffffff;
    font-weight: 500;
  }

  .value-label {
    color: #ffffff;
    font-weight: 500;
    word-break: break-all;
  }
}

.filestyle {
  &.form-control {
    border-color: rgba(255, 255, 255, 0.9) !important;
    border: 1px solid #ced4da !important;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.29) !important;
    height: 35px !important;
    padding-left: 10px !important;
  }

}

.bootstrap-filestyle {
  .form-control {
    border-radius: 6px 0 0 6px;
    background-color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
  }

  .group-span-filestyle {
    .btn {
      border-radius: 0;
      font-size: 14px;
      line-height: 28px;
      border: none !important;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.29) !important;
      background-color: #149dcd;
    }
  }
}

.white {
  .bootstrap-filestyle {
    .btn-outline-primary {
      color: #ffffff;
      border-color: #ffffff;
    }
  }
}

/*
* Header
*/
.navbar {
  padding: 7px 0;
  background-color: #139dcc;
  box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);

  .navbar-brand {
    padding: 0;
    width: 47px;
  }

  .navbar-nav {
    margin-bottom: 0 !important;

    li.nav-item {
      margin-left: 15px;
      float: left;

      a.nav-link {
        color: #000;
        text-decoration: none;
        padding: 10px;
        border-radius: 6px;
        font-size: 14px;

        &:hover,
        &:focus {
          color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
        }

        &.dropdown-toggle:hover {
          color: #fff;
          box-shadow: none;
        }

        &.active {
          color: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
        }
      }
      &.dropdown{
        .dropdown-toggle{
          &::after{
            color: #fff;
          }
          &:focus{
            border: none;
            outline: none!important;
            box-shadow: none!important;
          }
        }
      }
      .dropdown-menu.show {
        display: flex;
        flex-direction: column;
      }

      .dropdown-toggle {
        padding: 0 10px;
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        color: #fff;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        height: 38px;

        span {
          margin: 0 10px;
          max-width: 115px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }

        img {
          border: 2px solid #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
        }

        &::after {
          content: "\f107";
          font-family: "FontAwesome";
          border: none;
          font-size: 18px;
        }
      }

      .dropdown-menu {
        padding: 0;

        li {
          a.dropdown-item {
            font-size: 14px;
            padding: 7px;
          }

          &:first-child a {
            border-radius: 6px 6px 0 0;
          }

          &:last-child a {
            border-radius: 0 0 6px 6px;
          }
        }
      }
    }
  }

  .navbar-collapse {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .navbar-collapse.collapse.show {
    // background-color: #139dcc !important;
    // margin: 0 -15px !important;
    // border: 1px solid #0c5066;
    // padding: 15px 15px 15px 0;
  }

  .toggle-btn {
    // position: absolute;
    // right: auto;
    // left: 15px;
    // top: 15px;
    // bottom: 0;
    // z-index: 10 !important;
    font-size: 21px;
    color: #139dcc;
    background-color: #fff;
    height: 35px;
    width: 35px;
    box-shadow: none;
    padding: 3px 5px 5px 5px;
    z-index: 1001;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: #139dcc !important;
      background-color: #fff !important;
    }
  }
}

footer {
  padding: 5px 10px;
  background-color: #ffffff;
  box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  // z-index: 1005;

  p {
    font-size: 12px;
    color: #000;
    text-align: right;
  }
}

#map {
  height: 100%;
}

.fixed-top {
  z-index: 100 !important;
}

.sidebar {
  background-color: #ffffff;
  box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);
  position: fixed;
  top: 60px;
  left: 0px;
  right: auto;
  bottom: 0;
  width: 65px;
  z-index: 1000;
  overflow-y: auto;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;

  &.show {
    // left: 0px;
    width: 320px;

.sidebar-footer{
  width: 320px;

}
  }

  .mini-sidebar {
    span {
      display: none;
    }

    .accordion-button::after {
      display: none;
    }

    .accordion-body {
      display: none;
    }
  }

  .nav-list {
    li {
      a {
        padding: 15px;
        display: flex;
        align-items: center;
        color: #000;
        background-color: #fff;
       border-bottom: 1px solid  #139dcc;
        text-decoration: none;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        font-size: 15px;

        .fa {
          color: #000;
          margin-right: 15px;
        }

        &.active,
        &:hover {
          background-color: #139DCC;
          color: #fff;
          border: 2px solid #fff;

          .fa {
            color: #fff;
          }
        }
      }
    }
  }

  .accordion {

    .accordion-button:not(.collapsed){
      color: #139DCC!important;
    }
    .sub-accordian-item {
      background-color: #139DCC !important;

      .accordion-header {
        border-bottom: 1px solid transparent !important;
      }

      .accordion-button {
        background-color: #fff !important;

        &::after {
          color: #000 !important;
        }
      }

      .dog-name {
        color: #000 !important;

      }
    }

    // .accordion-item {
      // border: none;

      .accordion-header {
        // box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.42);
        // margin-bottom: 10px;
        border-bottom: 1px solid transparent;

        .accordion-button {
          padding: 10px;
          font-weight: bold;
          border-bottom: 1px solid #139dcc;
          &:focus {
            outline: none;
            box-shadow: none;
          }

          &::after {
            content: "\f107";
            font-family: "FontAwesome";
            font-size: 22px;
            width: 1rem;
            height: 1rem;
            background-size: 1rem;
            background-image: none;
            color: #149dcd;
          }
        }
      }

      // .accordion-collapse {
      //   background-color: #149dcd;
      //   border-radius: 0;
        .accordion-header {
          // .accordion-button {
          //   background-color: #149dcd;
          //   &.collapsed {
          //     background-color: #ffffff;
          //   }
          // }

        }
        .dog-bio {
          border: 3px solid #f78b56;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          max-width: 100%;
         max-height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            // width: 35px;
            // height: 35px;
            max-width: 100%;
            max-height: 100%;
            border-radius: 50%;
            // border: 1px solid #ffffff;

          }
        }

        // .accordion-body {
        //   padding: 5px;


          .bx.bx-bulb {
            font-size: 18px;
          }

          .dog-name {
            color: #000;
            font-size: 16px;
          }

          .dog-img {
            border: 2px solid #8de2ff;
            border-radius: 6px;
            // min-width: 145px;
            max-width: 100%;
            max-height: 135px;

          }

          .progress {
            height: 7px;
            background-color: #ffff;

            .progress-bar {
              background-color: #086585;
            }
          }

          .progress-value {
            font-size: 12px;
            color: #000;
          }

          .nav.nav-pills {
            .nav-link {
              color: #fff;
              font-size: 14px;
              padding: 8px 10px;

              &.active {
                color: #fff !important;
                background-color: #0983ad !important;
              }
            }
          }

          .place-list-scroll {
            max-height: 225px;
            overflow-y: auto;
            padding: 0 10px;

            .place-list {
              li {
                background-color: #ffffff;
                padding: 8px;
                border-radius: 7px;
                color: #000000;
                margin-bottom: 10px;

                .place-name {
                  font-size: 15px;
                  line-height: 16px;
                  font-weight: 600;
                  margin-bottom: 7px;
                }

                .date {
                  font-size: 14px;
                  margin-bottom: 0;
                  line-height: 16px;
                }

                .bxs-map {
                  font-size: 24px;
                  margin-right: 10px;
                  color: #0983ad;
                }
              }

              &.alerts {
                li {
                  background-color: #fff;
                  color: #000;

                  .place-name {
                    color: #000000;
                  }
                }
              }
            }
          }


        // }

        // &.show {
        //   background-color: #ffffff;
        // }
      // }

    // }
    .accordion-body{
      .nav-tabs{
        .nav-item{
          width: 50%;
        }
        .nav-link{
          width: 100%;
        }
      }
    }
  }
}

.accordion-flush {
  .accordion-button {
    height: 50px !important;
  }
}

.accordion-collapse {
  -webkit-transition: all 0.3s ease-in-out !important;
  -moz-transition: all 0.3s ease-in-out !important;
  -o-transition: all 0.3s ease-in-out !important;
  -ms-transition: all 0.3s ease-in-out !important;
  transition: all 0.3s ease-in-out !important;
}

#mainContent {
  // height: 100vh;
  position: relative;
  padding-left: 0;
  overflow: hidden;
  margin-left: 65px;
  margin-top: 60px;
  // width: 100%;
  -webkit-transition: margin-left 0.3s ease-in-out;
  -moz-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  -ms-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;

  // .toggle-btn {
  //   position: absolute;
  //   right: auto;
  //   left: 15px;
  //   // top: 15px;
  //   bottom: 35px;
  //   // z-index: 10 !important;
  //   // margin-top: 70px;
  //   // margin-left: 15px;
  //   font-size: 21px;
  //   color: #139dcc;
  //   // background-color: #fff;
  //   height: 35px;
  //   width: 35px;
  //   box-shadow: none;
  //   padding: 3px 5px 5px 5px;
  //   z-index: 1001;
  //   transition: all 0.3s ease-in-out;
  // &:hover, &:focus{
  //   color: #139dcc!important;
  //   background-color: #fff!important;
  // }
  // &:hover {
  //   color: #f78b56;
  //   background-color: #ffffff;
  // }
  // }

  // &.show {
  //   width: calc(100% - 320px);
  // }
}

.gMap {
  width: 100%;
  height: calc(100% - 60px);
}

#mainContent.show {
  margin-left: 320px;
  // width: calc(100% - 320px) !important;
}

.modal,
.modal-backdrop {
  position: absolute !important;


}

.modal {
  .check-change{
    .form-check-label{
      font-size: 14px;
      margin-top: 1px;

    }

  }
  padding: 0 15px;

  .modal-dialog.modal-xl {
    margin: 1.75rem auto;
  }

  &.show.large-modal .modal-dialog {
    max-width: 100%;
  }

  .modal-header {
    padding: 13px 20px;
    background-color: #139DCC;
    color: #fff;
    font-weight: 600;
    .btn-close{
    opacity: 1;
      background: transparent url(/src/images/close.png) center/1em auto no-repeat;
      &:focus{
        outline: none;
      }
    }
    .modal-title {
      font-size: 16px;
    }
  }

  .modal-body {
    // min-height: 500px;
    overflow-y: auto;

  }

  .modal-footer {
    padding: 5px 15px;
  }

  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: #a9a4a4 !important;
    border-color: #a9a4a4 !important;
  }
}

.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 80vh;

  p {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;

  }
}

.form-label {
  font-weight: 500;
  color: #139dcc;
  font-size: 14px;
}

.canvas-view {
  z-index: 0 !important;

  .btn-secondary {
    height: 32px !important;
    line-height: 18px;
    font-size: 14px;
    box-shadow: none;
    color: #000 !important;
    min-width: 60px;
    border-color: #a2a2a2;
    background-color: transparent !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &.active {
      background-color: #139dcc !important;
      border-color: #139dcc !important;
      color: #fff !important;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
&:focus{
  box-shadow: none;
  outline: none;
}
    // &:hover {
    //   background-color: #139dcc !important;
    //   border-color: #139dcc !important;
    //   color: #fff !important;
    //   -webkit-transition: all 0.3s ease-in-out;
    //   -moz-transition: all 0.3s ease-in-out;
    //   -o-transition: all 0.3s ease-in-out;
    //   -ms-transition: all 0.3s ease-in-out;
    //   transition: all 0.3s ease-in-out;
    // }


    &:focus {
      box-shadow: none !important;
      outline: none !important;
      background-color: #139dcc !important;
      border-color: #139dcc !important;
      color: #fff !important;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }

}

// .btn-group.canvas-view {
//   z-index: 0 !important;

//   .btn-outline-secondary {
//     height: 32px !important;
//     line-height: 18px;
//     font-size: 14px;
//     box-shadow: none;
//     color: #000;
//     min-width: 60px;
//     border-color: #a2a2a2;

//     -webkit-transition: all 0.3s ease-in-out;
//     -moz-transition: all 0.3s ease-in-out;
//     -o-transition: all 0.3s ease-in-out;
//     -ms-transition: all 0.3s ease-in-out;
//     transition: all 0.3s ease-in-out;

//     &:hover {
//       background-color: transparent;
//       border-color: #139dcc !important;
//       color: #000;
//     }

//     &:focus {
//       box-shadow: none;
//       outline: none;
//       color: #000;
//     }
//   }
// }

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff !important;
  background-color: #139dcc !important;
  border-color: #139dcc !important;
}

.zones-list {
  li {
    background-color: transparent;
    padding: 10px;
    color: #fff;
    border-radius: 6px;
    width: 50%;
    .nav-link{
      width: 100%;
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:hover {
      background-color: #0c83ab;
    }

    .zone-name {
      font-size: 14px;
      font-weight: 600;
    }

    div {
      .fa {
        font-size: 20px;
        color: #fff;
      }
    }
  }
}

.card {
  .card-header {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    padding: 13px 20px;
    background-color: #d9d9d9;
  }

  .section-title {
    font-size: 16px;
    color: #139dcc;
    font-weight: 600;
  }

  .card-body {
    label.caption {
      color: #646464;
    }

    label.value {
      color: #1e1414;
    }
  }

  &.parent>.card-body {
    overflow-y: auto;
  }

  .section-btn-panel {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}

.dog-bio {
  border: 3px solid #f78b56;
  border-radius: 50%;
  width: 80px;
  height: 80px;

  img {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 1px solid #ffffff;

  }
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.modal {
  .modal-body {
    label.caption {
      color: #646464;
    }

    label.value {
      color: #1e1414;
    }
  }
}

.my-pets {
  .form-control {
    border-radius: 0px;
    font-size: 13px;
    height: 35px;

    &::placeholder {
      font-size: 13px;
    }
  }

  .bg-image-holder {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 180px;
    width: 100%;
    border-radius: 0.25rem 0.25rem 0 0;
    // filter: blur(2px);
    // -webkit-filter: blur(2px);
    background-color: #0983ad;
  }

  .pet-info-container {
    // margin-top: -180px;
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    width: 100%;

    .pet-info-inner {
      padding: 1rem;

      label {
        margin-bottom: 7px !important;

      }
    }

  }

  .quick-icons {
    .btn {
      font-size: 20px;
      color: #139DCC;
      box-shadow: none;
    }
  }

  .dog-bio {
    border: 2px solid #f78b56;
    border-radius: 50%;
    width: 80px;
    height: 80px;

    img {
      width: 76px;
      height: 76px;
      border-radius: 50%;
      border: 1px solid #ffffff;
    }
  }

  // .input-group {
  //   border-radius: 0.375rem;
  // }

  .input-group,
  .dropdown__control,
  .custom-shadow {
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.29);
    border: 1px solid #ced4da;
  }

  &.modal-body {

    .caption-label,
    .value-label {
      color: #000 !important;
    }
  }
}

// .dropdown_menu {
//   z-index: 1000 !important;
// }

.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #a9acd699 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.my-device {
  .dog-bio {
    border: 2px solid #f78b56;
    border-radius: 50%;
    width: 80px;
    height: 80px;

    img {
      width: 76px;
      height: 76px;
      border-radius: 50%;
      border: 1px solid #ffffff;
    }


  }

  h4 {
    color: #139dcc;
    font-size: 18px;
    font-weight: 700;
  }

  .btn-remove {
    font-size: 20px;
    color: #139dcc;
    box-shadow: none;
  }
}

.edit-device,
.my-pets {
  &.modal-body {
    // height: 200px;
    overflow-y: unset;
  }
}

.table {
  thead {
    th {
      background-color: #ececec;
      color: #474747;
    }
  }

  &> :not(:first-child) {
    border-top: none;
  }

  .btn.btn-link {
    padding: 5px;
    box-shadow: none;
    color: #139dcc !important;
    &:focus{
      box-shadow: none;
      outline: none;
    }

    .fa {
      color: #139dcc;
      font-size: 18px;
    }
  }
}

.btn-link {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #139dcc !important;
  border-color: #139dcc !important;

  :focus {
    border-color: #139dcc;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(19, 257, 104, 0.25);
  }
}


// ADMIN PAGES STYLE

.chiller-theme.toggled {
  .page-heading {
    padding-left: 65px;

    h2 {
      display: block;
    }

    &.show {
      padding-left: 225px !important;

    }
  }
}


.page-heading {
  padding: 10px 0 10px 0;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.50); */
  position: fixed;
  width: 100%;
  left: 0;
  background-image: linear-gradient(to right, #0282af, #149ccc);
  z-index: 100;
  padding-left: 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  h2 {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    line-height: 43px;
    margin-bottom: 0px;
  }

  .btn-dark {
    background-color: #f38954;
    border-color: #f38954;
    float: left;
    margin-right: 15px;

    &:hover {
      color: #fff;
      background-color: #f38954;
      border-color: #f38954;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .dropdown-toggle {
    width: 220px;
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0;

    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .255em;
      content: "";
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      float: right;
      top: 50%;
      position: absolute;
      right: 13px;
    }
  }

  .user-pic {
    float: left;
    width: 45px;
    height: 41px;
    padding: 2px;
    margin-right: 15px;
    overflow: hidden;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }
  }

  .user-info {
    float: left;
    text-align: left;
    color: #fff;

    span {
      display: block;
      line-height: 20px;

      &.user-name {
        font-size: 16px;
        ;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 120px;
      }

      &.user-role {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 120px;
      }
    }
  }
}



.page-heading .btn-dark:not(:disabled):not(.disabled).active,
.page-heading .btn-dark:not(:disabled):not(.disabled):active,
.page-heading .show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #149ccc;
  border-color: #149ccc;
}

.page-heading .dropdown-toggle:focus,
.page-heading .btn-secondary:not(:disabled):not(.disabled).active,
.page-heading .btn-secondary:not(:disabled):not(.disabled):active,
.page-heading .show>.btn-secondary.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

// .page-heading .dropdown-menu {
//     top: 100% !important;
// }

.page-heading .user-info>.page-heading .user-info .page-heading .user-info footer {
  padding: 10px 0;
  background-color: #041b29;
}

footer p {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff !important;
}

.dashboard {
  .card {
    height: 300px;
    margin-bottom: 30px;
    border-radius: 0;
    box-shadow: 1px 0 17px rgba(0, 0, 0, 0.22);

    .card-header {
      padding: 10px;
      margin-bottom: 0;
      background-color: #fff;
      border-bottom: 1px solid transparent;
    }

    .card-body {
      padding: 0px;

    }
  }
}

.payment-details {
  .payment-info {
    h4 {
      font-size: 18px;
    }
  }

  .product-img {
    width: 100%;
    height: 250px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.18);
  }

}

.top-action h2 {
  color: #000;
  font-weight: 500;
  line-height: 30px;
  font-size: 16px;
}

// #search {
//   padding: 5px;
//   width: 75px;
//   border: none;
//   border-radius: 0px;
//   transition: width .5s ease;
//   border-bottom: 1px solid #000;

//   &:focus {
//     width: 200px;
//     box-shadow: none;
//     outline: none;
//   }
// }
.search-box {
  span.deleteicon {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    align-items: center;
    // border: 1px solid #000;
  }

  span.deleteicon span {
    position: absolute;
    display: block;
    right: 7px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #000;
    background-color: #ccc;
    font: 13px monospace;
    text-align: center;
    line-height: 1em;
    cursor: pointer;
  }

  span.deleteicon input {
    padding-right: 18px;
    box-sizing: border-box;
    border: 2px solid #f38954;
    border-radius: 5px;
    padding: 0px 5px 0px 5px;

    &::placeholder {
      font-size: 12px;
    }
  }
}

.dtqzBx div:first-child {
  white-space: break-spaces !important;

}

.main-wrapper {
  padding-top: 75px;

  .btn {
    &.btn-primary {
      // padding: 10px 15px 10px 15px;
      background-color: #f38954 !important;
      border: 1px solid #f38954;
      border-radius: 15px;
      transition: all 300ms ease-in-out;
    }

    &.active,
    &:hover,
    &:focus {
      color: #f38954 !important;
      background-color: #fff !important;
      border: 1px solid #f38954 !important;
      transition: all 300ms ease-in-out;
      box-shadow: none;
      outline: none;
    }

    &.btn-dark {
      background-color: #f38954;
      color: #fff;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .card {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.19);
    padding: 15px 15px 15px 15px;

  }

  .notification {
    margin-bottom: 40px;

    .notification-title {
      font-size: 16px;
      font-weight: 600;
    }

    .notification-content {
      font-size: 12px;
      color: #6f6f6f;

    }
  }
}

.account-btn {
  &.toggle-btn {
    top: 6px !important;
  }
}

.right-content {
  .form-control {
    width: 130px;
    font-size: 13px;
    font-weight: 500;
    outline: none;
    box-shadow: none;

    &::placeholder {
      color: #000 !important;
      font-size: 13px;
      padding-bottom: 5px;
    }
  }

  .fa-calendar-alt {
    color: #f38954;
  }
}

#timepicker {
  border: none !important;
  cursor: pointer;
}

// SUBSCRIPTION PLAN
.subscription-plan {
  .subplan {
    border-radius: 10px !important;

    &:hover .subplan-box {
      background-color: #f38954;

      transition: all 300ms ease-in-out;
    }
  }

  .subplan-box {
    background-color: #9a9a9a;
    color: #fff;
    border-radius: 5px;
    transition: all 300ms ease-in-out;

    &.active,
    &:hover {
      background-color: #f38954;
    }

    .plan-title {
      font-size: 21PX;
      font-weight: 500;
    }

    .plan-price {
      font-size: 30PX;
      font-weight: 600;
    }
  }

  .subplan-content {
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    margin-top: -15px;

    li::before {
      display: inline-block;
      font-size: 12px;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: #a6a6a5;
      margin-right: 15px;
      // position: absolute;
      // left: 30px;
    }

    .list-group-item {
      border: none !important;
    }

    .btn-primary {
      background-color: #fff !important;
      color: #f38954;
      border: 1px solid #f38954;
      border-radius: 18px;
      transition: all 300ms ease-in-out;

      &.active,
      &:hover,
      &:focus {
        background-color: #f38954 !important;
        border: 1px solid #f38954;
        color: #fff !important;
        transition: all 300ms ease-in-out;
      }
    }


  }


}

.add-plan-form {
  h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

}

.settings {
  .tab-content {
    .card {
      height: 350px;

      .card-title {
        font-size: 20px;
        font-weight: 700;
        color: #0e93c2;
      }

    }

  }
}

.nav-pills {
  .nav-link {
    color: #000;
    font-weight: 500;
    transition: all 300ms ease-in-out;
    border-radius: 10px;

    &.active,
    &:hover,
    &:focus,
    &.show>.nav-link {
      background-color: #f38954 !important;
      color: #fff;
      transition: all 300ms ease-in-out;
    }
  }
}


// DATATABLE STYLE
.idPHNo {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.rdt_TableRow {
  border: 1px solid #ccc !important;
  // box-shadow: 0px 0px 7px 0px rgba(0 0 0 ,0.18) !important;
  // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.19);
  margin-bottom: 12px !important;
}

.rdt_Pagination {
  font-weight: 600 !important;
  color: #000 !important;
}
 .fa .fa-pencil-square-o{
color: #139dcc!important;
}

.card-details{
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  label{
font-size: 14px;
font-weight: 600;
margin-bottom: 8px;
  }

}
.InputContainer {
  border-bottom: 1px solid #ccc!important;
}
.overflow-unset{
  overflow-y: unset!important;
}
.msg-green{
  color:green;
}
.msg-red{
  color:red;

}
.logout{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.18)!important;
}
.btn-link{
  text-decoration: none!important;
}
.flatpickr-input::placeholder, .form-control::placeholder{
  font-size: 12px!important;
}
.css-1dimb5e-singleValue, .css-qbdosj-Input{
  color: #000!important;
}
// -----------------MEDIA QUERY----------------------//

@media (max-width: 1366px) {}

@media only screen and (max-width: 1024px) {
  .page-heading .dropdown-toggle {
    width: 110px!important;}
  .page-wrapper.toggled .page-content {
    padding-left: 0;
  }

  .dashboard .card {
    height: 300px;
  }
  .pet-label, .label-info-1{
    font-size: 13px!important;
  }
  .my-device {
    .dog-bio {
    width: 50px;
    height: 50px;
    img {
      width: 45px;
      height: 45px;

    }
}
}

}

@media (max-width: 991px) {
  .login-img-holder {
    display: none;
  }

  .login-holder {
    background-image: url(../images/bg-image-tablet.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    padding: 50px 150px;
    min-height: 100vh;
  }

  .login-container {
    background-color: #149dcd;
  }
  #mainContent .nav-tabs .nav-item {
    width: 50%!important;
    .nav-link{
      width: 100%!important;
    }

}
.search-block {
  // display: block !important;
  // width: 80% !important;
  left: 10px !important;
  right: 10px !important;
  top: 65px !important;
  .pac-target-input{
    width: 220px!important;
  }
}

  .form-group .input-group.white .form-control,
  .form-group .form-control {
    margin-left: 0px !important;
  }
  .dropdown__placeholder .Select, .dropdown__single-value, .css-1jqq78o-placeholder{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .toggle-btn {
    padding: 4px 1px 1px 1px;
    border-radius: 6px;
    height: 35px;
    width: 35px;
    font-size: 20px;
    right: -45px;
    // align-items: center;
    // display: flex;
  }

  .login-holder.d-flex.align-items-center.billing-address,
  .login-holder.d-flex.align-items-center.pet-info,
  .login-holder.d-flex.align-items-center.confirmation {
    display: block !important;
    align-items: inherit !important;
  }

  .my-account {
    margin: 30px auto;
  }

  .card .section-btn-panel {
    position: relative;
    bottom: auto;
    right: auto;
    display: flex !important;
    justify-content: center !important;

    float: none !important;
  }

  .form-control.calender,
  .form-control.watch {
    background-size: 3% !important;
  }

  #settingModal .modal-body,
  #slippingModeModal .modal-body {
    height: auto !important;
  }
}

@media (max-width: 767px) {
  aside{
    display:none;
  }
  .navbar{
    .navbar-collapse{
      .navbar-nav {
        li{
          padding: 0px 4px;
        }
      }
    }
  }
  .accordion{
    .accordion-body{
      .quick-icons{
        float: right;
      }
    }
  }
  .mobile-list{
    border: 1px solid #fff;
    margin-top: 10px;
    // margin: 5px 15px;
    // border-radius: 10px;
    color: #fff;
    li{
      .fa{
        margin-right: 10px;
      }
    }
  }
  #mainContent {
    margin-left: 0px;
    // .scrollabble-pet{
    //   overflow-y: scroll;
    //   height: 400px;
    // }
    .accordion {

      .accordion-body{
        padding: 10px!important;
        .dog-bio{
          border-width: 2px!important;
          width: 45px!important;
          height: 45px!important;
        }
        .dog-bio img {
          width: 41px;
          height: 41px;
        }
        .dog-name{
          font-size: 15px;
        }}
    }
    &.sidebar.show {
      top: 0px!important;
    }
    .nav-tabs{
      width: 100%;
      .nav-item{
        width: 50%;
        .nav-link {
          width: 100%;
          &:hover {
            color: #fff !important;
            background-color: transparent !important;
            border-color: #fff !important;
        }
        }
      }
    }
    .nav-tabs li.nav-item{
      margin-bottom: 0px;
     .nav-link{
      padding: 10px 8px;
      font-size: 15px;
    }

    }
  }
  .dropdown  #mainContent.show {
    margin-top: 10px!important;

  }
  #mainContent.show {
    width: calc(100%) !important;
    // margin-top: 10px;

  }

  .search-block{

    display: block!important;
    width: 80%!important;
    left: 10px!important;
    right: 10px!important;
    top: 65px!important;
    input{
      width: 330px!important;
    }
    button{
      margin-left: 23%;
    }
  }
  .sidebar {
    z-index: 10 !important;
  }

  .navbar {
    .navbar-collapse {
      padding-top: 10px;
      height: 100vh;
      overflow-y: auto;
    }

    .navbar-toggler {
      color: #ffffff;
      border-color: #ffffff;
      box-shadow: none;
    }

    .navbar-nav {
      border-top: 1px solid #fff;
      padding-top: 15px;

      li.nav-item {
        margin-left: 0;
        margin-bottom: 10px;

        .dropdown-toggle {
          justify-content: flex-start;

          display: block !important;
          position: relative;
          height: 32px;

          img {
            float: left;
          }

          span {
            float: right;
            /* justify-content: flex-end; */
            padding-top: 6px;
            max-width: 200px;
          }

          &::after {
            float: right;
            top: 6px;
            position: absolute;
            right: 0;
          }
        }
      }
    }

  }

  .dropdown-toggle.show {
    margin-bottom: 10px;
  }

  .login-holder {
    padding: 15px;
    height: 100vh;
    background-attachment: fixed;
  }

  .login-holder .login-container {
    padding: 15px;
    .forgot-pwd{
      font-size: 13px;
    }
    .list-inline{
      .list-inline-item{
        width: 100%;
        .btn{
          font-size: 14px!important;
        }
      }
    }
  }

  .navbar .navbar-brand {
    // width: 12%;
  }
  .react-confirm-alert{
    .custom-ui{
      padding: 20px;
      .fa-3x{
        font-size: 30px;

      }
      h3{
        font-size: 15px;
      }
      .btn-outline-primary {
        width: 110px!important;
        padding: 8px 8px!important;
      }
    }
  }
.modal{
  .advanced-settings-container{
    .panel-container{
      label{
        font-size: 12px;
      }
      .btn-outline-primary{
        margin-left: 10px;
      }
    }
  }
  .modal-footer{
    .list-inline-item {
      .btn-outline-primary{
        font-size: 13px!important;
      }
    }
  }
}
  .modal .modal-dialog.modal-xl {
    margin: 1rem auto;
  }

  .card.parent {
    display: inline-block;
  }

  // .card.parent > .card-body {
  //   height: auto !important;
  // }
  // #mainContent {
  //   height: auto !important;
  // }
  .sidebar {
    top: 120px;
  }

  .sidebar.show {
    top: 120px;
    // left: -320px;
  }

  #mainContent.show {
    margin-left: 0;
  }

  .card.parent {
    display: flex;
  }


  .page-heading h2 {
    font-size: 16px;
    line-height: 30px;
  }

  .page-heading .dropdown-toggle {
    width: 50px;
  }

  .page-heading .user-pic {
    width: 30px;
    height: 30px;
  }

  .page-heading .user-info {
    display: none;
  }

  .page-heading .dropdown-toggle::after {
    right: 0;
  }

  .page-wrapper.toggled #show-sidebar {
    left: -40px;
    margin-top: 0px;
  }

  /* .info-box {
        margin-top: 0;
    } */
  .page-wrapper.chiller-theme.toggled .page-heading h2 {
    display: none;
  }

  .content-holder {
    padding-top: 15px;
  }


  .custom-control.custom-checkbox {
    font-size: 14px;
  }

  .forgot-password {
    font-size: 12px;
  }

  .profile .card {
    border: none;
  }

  .profile .card-body {
    padding: 0px !important;
  }
  .pet-info-container{
    .pet-info-inner{
      .list-inline{
        display: flex!important;
    justify-content: space-evenly!important;
      }
    }
  }

}

.admin-login {
  .login-holder {
    background-color: #fff !important;

    .login-container {
      background-color: #149dcd !important;
    }
  }

}

@media (max-width: 480px) {
  .btn {
    font-size: 13px;
    border-radius: 6px;
    height: 34px;
  }

  .login-holder.d-flex.align-items-center.billing-address,
  .login-holder.d-flex.align-items-center.pet-info,
  .login-holder.d-flex.align-items-center.confirmation {
    display: block !important;
    align-items: inherit !important;
  }

  .caption-label {
    margin-bottom: 7px;
  }

  .policy-text {
    text-align: justify;
  }

  .modal .modal-footer {
    padding: 5px 10px;
  }

  .modal .modal-footer.advance-search {
    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;

      li {
        float: none !important;
        margin: 0 !important;
      }
    }
  }

  .modal-dialog {
    margin: 1.5rem 0;
  }

  #settingModal .modal-body,
  #slippingModeModal .modal-body,
  #createPowerSavingZoneModal .modal-body,
  #createSafeZoneModal .modal-body {
    height: auto !important;

    .px-5 {
      padding: 0 !important;
    }
  }

  .modal-body.my-pets {
    .px-5 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    // .dropdown__menu{
    //   height: 200px!important;
    // }
  }

  .btn {
    height: auto !important;
  }

  #mainContent .toggle-btn {
    height: 40px !important;
  }

  .card-body.my-device {
    div.mb-3 {
      position: relative;
      display: block !important;

      .d-flex.align-items-center {
        // display: block !important;
        width: 100%;
      }

      .floating-btn {
        display: block !important;
        position: absolute;
        right: -15px;
        top: -15px;
        width: auto !important;
      }
    }
  }

  .min-width-120 {
    min-width: 100px;
  }
}
@media (max-width: 375px){
.modal {
  .modal-footer .list-inline-item .btn-outline-primary {
    font-size: 10px !important;
}
.modal-body{
  .px-5{
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}
}
}

.msg {
  p {
    line-height: 34px;
    text-align: center;
  }
}

// REACT-SELECT2 CSS

.login-container .css-1s2u09g-control,
.login-container .css-1pahdxg-control {
  background-color: #E8F5FA !important;
  border-color: #E8F5FA !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.css-14el2xx-placeholder {

  font-size: 12px !important;
  color: #6c757d !important;
}

.login-container .css-319lph-ValueContainer {
  padding: 2px 0px !important;
}

.login-container .css-1okebmr-indicatorSeparator {
  display: none;
}

.login-container .css-tlfecz-indicatorContainer,
.login-container.css-qc6sy-singleValue {
  font-size: 14px;
  color: #333333 !important;
}

.my-device .css-319lph-ValueContainer {
  padding-left: 5px !important;

  &::before {
    display: none !important;
  }
}

// .login-container .css-319lph-ValueContainer,
.my-pets .device-icon .css-319lph-ValueContainer {
  padding-left: 40px !important;

  &::before {
    font-family: 'FontAwesome';
    content: '\f8d9';
    position: absolute;
    left: 15px;
    color: #149dcd !important;
    font-size: 16px;
  }

}
.my-pets .css-319lph-ValueContainer {
  padding-left: 40px !important;

  &::before {
    font-family: 'FontAwesome';
    content: '\f017';
    position: absolute;
    left: 15px;
    color: #149dcd !important;
    font-size: 16px;
  }

}

.login-container .css-1fdsijx-ValueContainer,
.login-container .css-319lph-ValueContainer,
.edit-device .css-319lph-ValueContainer {
  padding-left: 40px !important;

  &::before {
    font-family: 'FontAwesome';
    content: '\f1b0';
    position: absolute;
    left: 15px;
    color: #149dcd !important;
    font-size: 16px;
  }

}

.login-container .species,
.my-pets .species {
  .css-319lph-ValueContainer, .css-1fdsijx-ValueContainer {
    &::before {
      content: '\f1b0';
    }
  }
}

.login-container,
.my-pets {
  .gender {
    .css-319lph-ValueContainer, .css-1fdsijx-ValueContainer{
      &::before {
        font-family: 'FontAwesome' !important;
        content: '\f228' !important;
      }
    }
  }
}

.login-container .country {
  .css-319lph-ValueContainer {
    &::before {
      content: '\f0ac' !important;
    }
  }
}

.login-container .timezone {
  .css-319lph-ValueContainer {
    &::before {
      content: '\f017' !important;
    }
  }
}

.timezone .css-319lph-ValueContainer {
  padding-left: 40px !important;

  &::before {
    font-family: 'FontAwesome';
    content: '\f017';
    position: absolute;
    left: 15px;
    color: #149dcd !important;
    font-size: 16px;
  }

}

.country .css-319lph-ValueContainer {
  padding-left: 40px !important;

  &::before {
    font-family: 'FontAwesome';
    content: '\f0ac';
    position: absolute;
    left: 15px;
    color: #149dcd !important;
    font-size: 16px;
  }

}

.my-pets {

  .card {
    margin-bottom: 15px;
  }

  .errors {
    color: #DC3545;
  }

  .css-1s2u09g-control {
    border-radius: 0px !important;
  }
}

.setting-device {
  .errors {
    color: #DC3545;
  }

}

.my-alerts {
  .css-14el2xx-placeholder {
    font-size: 14px;

    .css-1okebmr-indicatorSeparator {
      background-color: transparent !important;
    }

    .css-tlfecz-indicatorContainer {
      color: #000 !important;
    }

    .css-1s2u09g-control {
      background-color: rgba(255, 255, 255, 0.9) !important;
    }

    .css-qc6sy-singleValue {
      font-size: 14px !important;
    }
  }
}

._loading_overlay_wrapper {
  z-index: 1050;

  &.css-79elbk {
    position: unset;
  }
}

// .birth-date {
//   padding-left: 40px !important;

//   &::before {
//     content: '\f073';
//     font-family: 'FontAwesome';
//     position: absolute;
//     left: 15px;
//     color: #149dcd !important;
//     font-size: 16px;
//   }
// }


.dropdown__menu,
.css-2613qy-menu {
  font-size: 14px !important;
  // z-index: 100 !important;
}

.css-qc6sy-singleValue {
  font-size: 14px !important;
}

.css-14el2xx-placeholder {
  font-size: 14px;
}

.my-device {
  .table {
    font-size: 14px;

    thead th {
      border-bottom: 2px solid #fff !important;
    }

    tr {
      border-color: #fff !important;
    }
  }
}

#primary_color {
  // border-radius: 50%;
  height: 40px;
  max-width: 80px;
  border: none;
  outline: none;
  -webkit-appearance: none;
}

#primary_color::-webkit-color-swatch-wrapper {
  padding: 0;
}

#primary_color::-webkit-color-swatch {
  border: none;
  // border-radius: 50%;
}

//SPINNER
.spinner-border {
  margin-right: 5px !important;
  width: 17px !important;
  height: 17px !important;
  // border: 3px solid currentColor!important;
}

.btn-bg {
  background-color: #f78b56 !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}

.icon-btn {
  background-color: transparent;
  border: none;
}


.toggle {
  --width: 80px;
  --height: calc(var(--width) / 3);

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  cursor: pointer;

  input {
    display: none;

    &:checked+.slider {
      border-color: #139dcc;
    }

    &:checked+.slider::before {
      border-color: #139dcc;
      background-color: #139dcc;
      transform: translateX(calc(var(--width) - var(--height)));
    }

    &:checked~.labels::after {
      opacity: 0;
    }

    &:checked~.labels::before {
      opacity: 1;
    }
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 2px solid #969696;
    // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0px;
      bottom: 0px;
      right: 0px;
      width: 24px;
      /* height: 20px; */
      border-radius: 0px;
      border: 3px solid #969696;
      background-color: #969696;
      // box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
      transition: all 0.4s ease-in-out;
    }
  }

  .labels {
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 100%;
    color: #4d4d4d;
    font-size: 12px;
    font-family: sans-serif;
    transition: all 0.4s ease-in-out;

    &::after {
      content: attr(data-off);
      position: absolute;
      right: 5px;
      bottom: 12px;
      opacity: 1;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      transition: all 0.4s ease-in-out;
    }

    &::before {
      content: attr(data-on);
      position: absolute;
      left: 7px;
      bottom: 12px;
      opacity: 0;
      text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
      transition: all 0.4s ease-in-out;
    }
  }
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}




.custom-ui {
  text-align: center;
  border-radius: 4px;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;

  h1 {
    margin-top: 0;
  }

  .btn-outline-primary {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff !important;
    border-radius: 4px;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff !important;
    font-size: 14px;

    &:hover {
      border: 1px solid #f38954 !important;
    }
  }
}



// .toggle input {
//   display: none;
// }

// .toggle .slider {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   border-radius: 4px;
//   border: 2px solid #969696;
//   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
//   transition: all 0.4s ease-in-out;
// }

// .toggle .slider::before {
//   content: "";
//   position: absolute;
//   top: 2.5px;
//   left: 2px;
//   width: calc(var(--height)*0.6);
//   height: calc(var(--height)*0.6);
//   border-radius: calc(var(--height) / 2);
//   border: 3px solid #969696;
//   background-color: #dbdbdb;
//   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
//   transition: all 0.4s ease-in-out;
// }

// .toggle input:checked+.slider {
//   border-color: #139dcc;
// }

// .toggle input:checked+.slider::before {
//   border-color: #139dcc;
//   background-color: #c6e5ff;
//   transform: translateX(calc(var(--width) - var(--height)));
// }

// .toggle .labels {
//   position: absolute;
//   top: 8px;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   color: #4d4d4d;
//   font-size: 12px;
//   font-family: sans-serif;
//   transition: all 0.4s ease-in-out;
// }

// .toggle .labels::after {
//   content: attr(data-off);
//   position: absolute;
//   right: 5px;
//   bottom: 12px;
//   opacity: 1;
//   text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
//   transition: all 0.4s ease-in-out;
// }

// .toggle .labels::before {
//   content: attr(data-on);
//   position: absolute;
//   left: 7px;
//   bottom: 12px;
//   opacity: 0;
//   text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
//   transition: all 0.4s ease-in-out;
// }

// .toggle input:checked~.labels::after {
//   opacity: 0;
// }

// .toggle input:checked~.labels::before {
//   opacity: 1;
// }
.data-label {
  font-size: 13px;
}

.icon-color .dropdown__value-container{padding-left: 40px;}
.icon-color .dropdown__value-container::before ,
.icon-color .dropdown__value-container .css-1fdsijx-ValueContainer::before ,
.icon-color .css-319lph-ValueContainer::before {
  font-family: "FontAwesome";
  content: "\f111" !important;
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.calender .css-319lph-ValueContainer::before {
  font-family: "FontAwesome";
  content: "\f073" !important;
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.modal {
  .modal-body {
    .nav-tabs {
      .nav-item .nav-link {
        color: #000 !important;
        border: 1px solid #e7e4e4;
        padding: 8px;
        font-size: 14px;
        transition: all 300ms ease-in-out;

        &.active,
        &:hover {
          background-color: #f38954 !important;
          color: #fff !important;
          border-color: #f38954 !important;
          transition: all 300ms ease-in-out;
        }
      }
    }

    .tab-content {
      .tab-pane {
        .file-upload {
          p {
            font-size: 15px;
          }

          // label {
          //   background-color: white;
          //   color: black;
          //   content:"gdg";
          //   padding: 0.5rem;
          //   font-family: sans-serif;
          //   border: 2px solid black;
          //   border-radius: 0.3rem;
          //   cursor: pointer;
          //   margin-top: 1rem;
          //   // padding: 10px 55px;
          // }

          // #file-chosen{
          //   margin-left: 0.3rem;
          //   font-family: sans-serif;
          // }
        }
      }

      .file-control {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        align-items: center;
      }
    }
  }


}

.view-structure {
  .nav-tabs {
    .nav-item {
      .btn-primary {
        margin-right: 10px;
        font-size: 13px;
      }

      .icon {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
        position: relative;
        height: 25px;
        width: 20px;

        &.icon-list {
          background-image: url(../images/list_black.png);

          &:hover,
          &.active,
          &:focus {
            background-image: url(../images/list_o.png);
          }

        }

        &.icon-grid {
          background-image: url(../images/grid_b.png);

          &:hover,
          &.active,
          &:focus {
            background-image: url(../images/grid_o.png);
          }

        }
      }
    }

    .nav-link {
      border: none;
      border: 1px solid #000 !important;
      border-radius: 5px !important;
      padding: 5px 10px;
      margin-right: 10px;

      &:hover,
      &.active {
        color: #f38954 !important;
        border: 1px solid #f38954 !important;
      }

    }
  }

  .tab-content {
    clear: both;
  }
}

.instruction{
  font-size: 14px;
}
.instruction-title{
  cursor: pointer;
  font-size: 15px;
}

.timezone .css-1fdsijx-ValueContainer {
  padding-left: 40px !important;

  &::before {
    font-family: 'FontAwesome';
    content: '\f017';
    position: absolute;
    left: 15px;
    color: #149dcd !important;
    font-size: 16px;
  }

}
.gender {
  .css-319lph-ValueContainer, .css-1fdsijx-ValueContainer{
    padding-left: 40px;
    &::before {
      font-family: 'FontAwesome' !important;
      content: '\f228' !important;
            font-family: 'FontAwesome';
            position: absolute;
            left: 15px;
            color: #149dcd !important;
            font-size: 16px;
    }
  }
}
.species {
  .css-319lph-ValueContainer, .css-1fdsijx-ValueContainer{
    padding-left: 40px;
    &::before {
      font-family: 'FontAwesome' !important;
      content: '\f1b0' !important;
            font-family: 'FontAwesome';
            position: absolute;
            left: 15px;
            color: #149dcd !important;
            font-size: 16px;
    }
  }
}
 .device-icon .css-1fdsijx-ValueContainer {
  padding-left: 40px !important;

  &::before {
    font-family: 'FontAwesome';
    content: '\f8d9';
    position: absolute;
    left: 15px;
    color: #149dcd !important;
    font-size: 16px;
  }

}
 .country .css-1fdsijx-ValueContainer {
  padding-left: 40px !important;

  &::before {
    font-family: 'FontAwesome';
    content: '\f0ac';
    position: absolute;
    left: 15px;
    color: #149dcd !important;
    font-size: 16px;
  }

}
// .login-container .species,
//   .css-1fdsijx-ValueContainer {

//     &::before {
//       content: '\f1b0';
//       font-family: 'FontAwesome';
//       position: absolute;
//       left: 15px;
//       color: #149dcd !important;
//       font-size: 16px;

//   }
// }
.dropdown__placeholder .Select, .dropdown__single-value, .css-1jqq78o-placeholder {
  font-size: 12px!important;
}
 .dropdown__single-value {
  font-size: 13px!important;
   margin-left: 24px !important;
}
.infoBox{
  width: auto!important;
}
.navbar-toggler:focus{
  box-shadow: none!important;
  outline: none!important;
}
.history-section .accordion-header,
.event-section .accordion-header {
  border-bottom:none!important;
}
.css-1dimb5e-singleValue, .css-qbdosj-Input{
  font-size: 13px;
}
._loading_overlay_overlay{
  z-index: 1200!important;
}
.add-new-pet {
  .modal-body{
    #actual-btn{
      margin-top: 15px;
    }
  }
}

.note-box{
  padding: 22px 0px 10px 0px;
  border-radius: 10px;
  border: 1px solid;
  margin-top: -20px;
 p{ font-size: 14px;}
  span{
    font-size: 13px;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
}
.set-dropdown-icon{
  position: relative;
  }
  .set-dropdown-icon .adjust-icon{
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 1;
  }
  .set-dropdown-icon .css-1fdsijx-ValueContainer,
  .set-dropdown-icon .dropdown__value-container--has-value{
    padding-left: 35px!important;
  }
