
.advanced-settings-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media (max-width: 767px) {
    .advanced-settings-container {
        display: block;
}
.advanced-settings-container .panel-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.advanced-settings-container .panel-container .btn-outline-primary{ 
    margin: 15px 0px 0px 0px;
 }
.panel-container:first-child {
    border-right: none!important;
}
.form-group {
    margin-bottom: 0px;
}
}