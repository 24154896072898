.activeButton {
    background-color: #f78b56 !important;
    border: thin solid #f78b56;
    color: white;
}

.activeButton:disabled {
    border: thin solid white;
    color: white;
}
