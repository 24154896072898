.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    /* &:hover {
      z-index: 1;
    } */
  }

.marker-label {
    border: thin solid black;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    padding-top: 6px;
}

.location-label {
    border: thin solid black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #FFFFFF;
}
