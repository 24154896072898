* {
  padding: 0;
  margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.h-90 {
  height: 95% !important;
}

body {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Arimo", sans-serif !important;
}

.accordion-button:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.plan-validity {
  font-size: 14px;
  font-weight: 500;
}

p {
  line-height: 20px;
}

.btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.text-align-justify {
  text-align: justify;
}

.text-blue {
  color: #149dcd;
}

.find-btn {
  padding: 10px;
  border-radius: 5px !important;
}

.find-btn i {
  color: #139DCC;
}

.icon-button {
  color: #606060;
  font-size: 14px;
  border: none;
  background-color: transparent;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.icon-button:hover {
  color: #f38954;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.login-bg {
  background-image: url(../images/bg-signin.jpg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  width: 100%;
  height: 100% !important;
}

.login-holder {
  background-color: #149dcd;
  height: 100vh;
  padding: 50px 70px;
  overflow-y: auto;
  min-height: 100%;
}

.login-holder .login-container {
  border-radius: 10px;
  padding: 20px 30px;
  width: 100%;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.23);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.23);
}

.login-holder .login-container .check-change .form-check-label {
  font-size: 14px;
  margin-top: 1px;
}

.login-holder .login-container .logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.login-holder .login-container .logo img {
  display: block;
  margin: 0 auto;
  width: 40%;
}

.login-holder .login-container .panel-title {
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  margin-bottom: 15px;
}

.login-holder .login-container .forgot-pwd {
  float: right;
  color: #fff;
  text-decoration: none;
}

.login-holder .login-container .list-inline {
  margin-top: 40px;
}

.login-holder .login-container .list-inline .list-inline-item {
  width: 50%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 14px;
}

.form-group .input-group .input-group-text {
  min-width: 42px;
  max-width: 42px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #ffffff;
}

.form-group .input-group.white .input-group-text {
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
}

.form-group .input-group.white .input-group-text .fa,
.form-group .input-group.white .input-group-text .bx,
.form-group .input-group.white .input-group-text .fa-money-bill-1,
.form-group .input-group.white .input-group-text .fa-calendar-check,
.form-group .input-group.white .input-group-text .fa-file-invoice-dollar {
  color: #149dcd;
}

.form-group .input-group.white .input-group-text .bx {
  font-size: 20px;
}

.form-group .input-group.white .form-control {
  margin-left: 0px !important;
  border: none;
  color: #000;
}

.form-group .form-control {
  height: 40px;
  font-size: 14px;
}

.form-group .form-control.white {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9);
  padding-left: 0;
}

.form-group .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.form-group .form-control::-webkit-input-placeholder {
  font-size: 12px;
}

.form-group .form-control:-ms-input-placeholder {
  font-size: 12px;
}

.form-group .form-control::-ms-input-placeholder {
  font-size: 12px;
}

.form-group .form-control::placeholder {
  font-size: 12px;
}

.btn {
  border-radius: 6px;
  font-size: 15px;
}

.min-width-120 {
  min-width: 120px;
}

.btn-outline-primary {
  border-color: #a2a2a2 !important;
  color: #000 !important;
}

.btn-outline-primary.white {
  color: #ffffff !important;
  border-color: #ffffff !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-outline-primary.white:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.btn-outline-primary:hover, .btn-outline-primary:focus {
  color: #fff !important;
  background-color: #f78b56 !important;
  border-color: #f78b56 !important;
}

.device-plan .form-check-label {
  color: #000 !important;
  padding: 9px !important;
  border: 1px solid #000 !important;
}

.device-plan [type="radio"]:checked + label.form-check-label {
  border: 1px solid #139DCC !important;
  color: #fff !important;
  background-color: #139DCC !important;
  border-color: #139DCC !important;
  -webkit-box-shadow: 0 0 10px rgba(19, 157, 204, 0.9) !important;
          box-shadow: 0 0 10px rgba(19, 157, 204, 0.9) !important;
}

.check-change .form-check-input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.check-change .form-check-input:checked {
  border: 1px solid #000 !important;
  color: #fff !important;
  background-color: #f78b56 !important;
  border-color: #f78b56 !important;
}

.custom-check.white {
  padding-left: 0;
}

.custom-check.white [type="radio"]:checked,
.custom-check.white [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.custom-check.white .form-check-label {
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 15px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom-check.white .form-check-label::after {
  content: inherit;
}

.custom-check.white .form-check-label .plan-name {
  float: left;
  font-weight: 700;
}

.custom-check.white .form-check-label .plan-price {
  float: right;
  font-size: 24px;
  font-weight: 500;
}

.custom-check.white [type="radio"]:checked + label.form-check-label {
  border-width: 1px;
  background-color: #f78b56;
  border-color: #f78b56;
  -webkit-box-shadow: 0 0 10px rgba(247, 139, 86, 0.9);
          box-shadow: 0 0 10px rgba(247, 139, 86, 0.9);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ffffff !important;
  border-color: #ffffff !important;
  background-color: transparent !important;
}

/******************* Custome Select *****************/
.input-group .custom-dropdown {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

/***************** White Dropdown *************/
.input-group.white .select2-container--default .select2-selection--single {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  background-color: rgba(255, 255, 255, 0.9);
  border: none !important;
  margin-left: 1px !important;
}

.white .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.white .select2-container--default .select2-selection--single:focus {
  border-color: rgba(255, 255, 255, 0.9) !important;
}

.propositions-blocks .custom-dropdown .select2-container {
  max-width: 230px;
  margin: 0 auto;
  display: block;
}

.custom-dropdown .select2-container {
  width: 100% !important;
  /* min-width: 170px; */
}

.select2-search__field:focus {
  outline: none !important;
  font-size: 14px !important;
}

.select2-container--default .select2-selection--single {
  border-color: #ced4da !important;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, .10) !important; */
}

.select2-container .select2-selection--single {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 40px !important;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000 !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  font-size: 14px !important;
  text-align: left;
  padding: 8px 30px 8px 8px !important;
}

.input-group.white .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 0 !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.custom-dropdown.text-white .select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #fff !important;
}

.custom-dropdown.text-white .select2-container--default .select2-selection--single .select2-selection__arrow .fa {
  color: #fff !important;
}

.custom-dropdown.text-white .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fff !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow .fa {
  color: #000000 !important;
  font-size: 20px !important;
  position: absolute !important;
  top: 7px !important;
  right: 4px !important;
  font-weight: bold !important;
}

.select2-results__option {
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 8px 10px !important;
}

.select2-dropdown {
  border-radius: 6px !important;
  border: 1px solid #ced4da !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.select2-container--default .select2-selection--single:focus {
  outline: none !important;
  border-color: #86b7fe !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #666666 !important;
  font-size: 14px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px !important;
  top: 2px !important;
  right: 5px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #139dcc !important;
  color: white;
}

.select2-results .select2-results__options li:first-child {
  border-radius: 6px 6px 0 0 !important;
}

.select2-results .select2-results__options li:last-child {
  border-radius: 0 0 6px 6px !important;
}

.select2-container .select2-selection--multiple {
  min-height: 38px !important;
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #e5e5e5 !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 5px !important;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 4px !important;
  line-height: 27px !important;
  font-size: 16px !important;
  color: #000000 !important;
  padding: 0px 8px 0px 3px !important;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-input-placeholder {
  color: #000000 !important;
}

.select2-container .select2-search--inline .select2-search__field:-ms-input-placeholder {
  color: #000000 !important;
}

.select2-container .select2-search--inline .select2-search__field::-ms-input-placeholder {
  color: #000000 !important;
}

.select2-container .select2-search--inline .select2-search__field::placeholder {
  color: #000000 !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: 8px !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #e5a205 1px !important;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(229, 162, 5, 0.25) !important;
          box-shadow: 0 0 0 0.2rem rgba(229, 162, 5, 0.25) !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #1a1a18 !important;
  border: 1px solid #1a1a18 !important;
  color: #fff !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff !important;
}

.errors {
  margin-top: 0.25rem;
  font-size: .875em;
  color: #fff;
}

.invalid-feedback {
  color: #fff !important;
}

/******************* Custome Select *****************/
.form-control.calender {
  position: relative;
  background-image: url(../images/calender-icon.png);
  background-repeat: no-repeat;
  padding-right: 30px;
  background-position: 97% center;
  background-size: 5%;
}

.form-control.watch {
  position: relative;
  background-image: url(../images/watch-icon.png);
  background-repeat: no-repeat;
  padding-right: 30px;
  background-position: 97% center;
  background-size: 5%;
}

.list-group-numbered > li {
  line-height: 28px;
}

.list-group-numbered > li::before {
  margin-left: -18px;
}

.version-detials {
  font-size: 14px;
  color: #ffffff;
}

.view-data .caption-label {
  color: #ffffff;
  font-weight: 500;
}

.view-data .value-label {
  color: #ffffff;
  font-weight: 500;
  word-break: break-all;
}

.filestyle.form-control {
  border-color: rgba(255, 255, 255, 0.9) !important;
  border: 1px solid #ced4da !important;
  height: 35px !important;
  padding-left: 10px !important;
}

.bootstrap-filestyle .form-control {
  border-radius: 6px 0 0 6px;
  background-color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.9);
}

.bootstrap-filestyle .group-span-filestyle .btn {
  border-radius: 0;
  font-size: 14px;
  line-height: 28px;
  border: none !important;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.29) !important;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.29) !important;
  background-color: #149dcd;
}

.white .bootstrap-filestyle .btn-outline-primary {
  color: #ffffff;
  border-color: #ffffff;
}

/*
* Header
*/
.navbar {
  padding: 7px 0;
  background-color: #139dcc;
  -webkit-box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);
          box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);
}

.navbar .navbar-brand {
  padding: 0;
  width: 47px;
}

.navbar .navbar-nav {
  margin-bottom: 0 !important;
}

.navbar .navbar-nav li.nav-item {
  margin-left: 15px;
  float: left;
}

.navbar .navbar-nav li.nav-item a.nav-link {
  color: #000;
  text-decoration: none;
  padding: 10px;
  border-radius: 6px;
  font-size: 14px;
}

.navbar .navbar-nav li.nav-item a.nav-link:hover, .navbar .navbar-nav li.nav-item a.nav-link:focus {
  color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
}

.navbar .navbar-nav li.nav-item a.nav-link.dropdown-toggle:hover {
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.navbar .navbar-nav li.nav-item a.nav-link.active {
  color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
}

.navbar .navbar-nav li.nav-item.dropdown .dropdown-toggle::after {
  color: #fff;
}

.navbar .navbar-nav li.nav-item.dropdown .dropdown-toggle:focus {
  border: none;
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.navbar .navbar-nav li.nav-item .dropdown-menu.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.navbar .navbar-nav li.nav-item .dropdown-toggle {
  padding: 0 10px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 38px;
}

.navbar .navbar-nav li.nav-item .dropdown-toggle span {
  margin: 0 10px;
  max-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.navbar .navbar-nav li.nav-item .dropdown-toggle img {
  border: 2px solid #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
}

.navbar .navbar-nav li.nav-item .dropdown-toggle::after {
  content: "\f107";
  font-family: "FontAwesome";
  border: none;
  font-size: 18px;
}

.navbar .navbar-nav li.nav-item .dropdown-menu {
  padding: 0;
}

.navbar .navbar-nav li.nav-item .dropdown-menu li a.dropdown-item {
  font-size: 14px;
  padding: 7px;
}

.navbar .navbar-nav li.nav-item .dropdown-menu li:first-child a {
  border-radius: 6px 6px 0 0;
}

.navbar .navbar-nav li.nav-item .dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px;
}

.navbar .navbar-collapse {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar .toggle-btn {
  font-size: 21px;
  color: #139dcc;
  background-color: #fff;
  height: 35px;
  width: 35px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 3px 5px 5px 5px;
  z-index: 1001;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navbar .toggle-btn:hover, .navbar .toggle-btn:focus {
  color: #139dcc !important;
  background-color: #fff !important;
}

footer {
  padding: 5px 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);
          box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

footer p {
  font-size: 12px;
  color: #000;
  text-align: right;
}

#map {
  height: 100%;
}

.fixed-top {
  z-index: 100 !important;
}

.sidebar {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);
          box-shadow: 1px 0 16px rgba(0, 0, 0, 0.42);
  position: fixed;
  top: 60px;
  left: 0px;
  right: auto;
  bottom: 0;
  width: 65px;
  z-index: 1000;
  overflow-y: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.sidebar.show {
  width: 320px;
}

.sidebar.show .sidebar-footer {
  width: 320px;
}

.sidebar .mini-sidebar span {
  display: none;
}

.sidebar .mini-sidebar .accordion-button::after {
  display: none;
}

.sidebar .mini-sidebar .accordion-body {
  display: none;
}

.sidebar .nav-list li a {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid  #139dcc;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
}

.sidebar .nav-list li a .fa {
  color: #000;
  margin-right: 15px;
}

.sidebar .nav-list li a.active, .sidebar .nav-list li a:hover {
  background-color: #139DCC;
  color: #fff;
  border: 2px solid #fff;
}

.sidebar .nav-list li a.active .fa, .sidebar .nav-list li a:hover .fa {
  color: #fff;
}

.sidebar .accordion .accordion-button:not(.collapsed) {
  color: #139DCC !important;
}

.sidebar .accordion .sub-accordian-item {
  background-color: #139DCC !important;
}

.sidebar .accordion .sub-accordian-item .accordion-header {
  border-bottom: 1px solid transparent !important;
}

.sidebar .accordion .sub-accordian-item .accordion-button {
  background-color: #fff !important;
}

.sidebar .accordion .sub-accordian-item .accordion-button::after {
  color: #000 !important;
}

.sidebar .accordion .sub-accordian-item .dog-name {
  color: #000 !important;
}

.sidebar .accordion .accordion-header {
  border-bottom: 1px solid transparent;
}

.sidebar .accordion .accordion-header .accordion-button {
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #139dcc;
}

.sidebar .accordion .accordion-header .accordion-button:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.sidebar .accordion .accordion-header .accordion-button::after {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 22px;
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
  background-image: none;
  color: #149dcd;
}

.sidebar .accordion .dog-bio {
  border: 3px solid #f78b56;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  max-width: 100%;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sidebar .accordion .dog-bio img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.sidebar .accordion .bx.bx-bulb {
  font-size: 18px;
}

.sidebar .accordion .dog-name {
  color: #000;
  font-size: 16px;
}

.sidebar .accordion .dog-img {
  border: 2px solid #8de2ff;
  border-radius: 6px;
  max-width: 100%;
  max-height: 135px;
}

.sidebar .accordion .progress {
  height: 7px;
  background-color: #ffff;
}

.sidebar .accordion .progress .progress-bar {
  background-color: #086585;
}

.sidebar .accordion .progress-value {
  font-size: 12px;
  color: #000;
}

.sidebar .accordion .nav.nav-pills .nav-link {
  color: #fff;
  font-size: 14px;
  padding: 8px 10px;
}

.sidebar .accordion .nav.nav-pills .nav-link.active {
  color: #fff !important;
  background-color: #0983ad !important;
}

.sidebar .accordion .place-list-scroll {
  max-height: 225px;
  overflow-y: auto;
  padding: 0 10px;
}

.sidebar .accordion .place-list-scroll .place-list li {
  background-color: #ffffff;
  padding: 8px;
  border-radius: 7px;
  color: #000000;
  margin-bottom: 10px;
}

.sidebar .accordion .place-list-scroll .place-list li .place-name {
  font-size: 15px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}

.sidebar .accordion .place-list-scroll .place-list li .date {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 16px;
}

.sidebar .accordion .place-list-scroll .place-list li .bxs-map {
  font-size: 24px;
  margin-right: 10px;
  color: #0983ad;
}

.sidebar .accordion .place-list-scroll .place-list.alerts li {
  background-color: #fff;
  color: #000;
}

.sidebar .accordion .place-list-scroll .place-list.alerts li .place-name {
  color: #000000;
}

.sidebar .accordion .accordion-body .nav-tabs .nav-item {
  width: 50%;
}

.sidebar .accordion .accordion-body .nav-tabs .nav-link {
  width: 100%;
}

.accordion-flush .accordion-button {
  height: 50px !important;
}

.accordion-collapse {
  -webkit-transition: all 0.3s ease-in-out !important;
  transition: all 0.3s ease-in-out !important;
}

#mainContent {
  position: relative;
  padding-left: 0;
  overflow: hidden;
  margin-left: 65px;
  margin-top: 60px;
  -webkit-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
}

.gMap {
  width: 100%;
  height: calc(100% - 60px);
}

#mainContent.show {
  margin-left: 320px;
}

.modal,
.modal-backdrop {
  position: absolute !important;
}

.modal {
  padding: 0 15px;
}

.modal .check-change .form-check-label {
  font-size: 14px;
  margin-top: 1px;
}

.modal .modal-dialog.modal-xl {
  margin: 1.75rem auto;
}

.modal.show.large-modal .modal-dialog {
  max-width: 100%;
}

.modal .modal-header {
  padding: 13px 20px;
  background-color: #139DCC;
  color: #fff;
  font-weight: 600;
}

.modal .modal-header .btn-close {
  opacity: 1;
  background: transparent url(/src/images/close.png) center/1em auto no-repeat;
}

.modal .modal-header .btn-close:focus {
  outline: none;
}

.modal .modal-header .modal-title {
  font-size: 16px;
}

.modal .modal-body {
  overflow-y: auto;
}

.modal .modal-footer {
  padding: 5px 15px;
}

.modal .btn-outline-primary.disabled,
.modal .btn-outline-primary:disabled {
  color: #a9a4a4 !important;
  border-color: #a9a4a4 !important;
}

.no-data-found {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  height: 80vh;
}

.no-data-found p {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}

.form-label {
  font-weight: 500;
  color: #139dcc;
  font-size: 14px;
}

.canvas-view {
  z-index: 0 !important;
}

.canvas-view .btn-secondary {
  height: 32px !important;
  line-height: 18px;
  font-size: 14px;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #000 !important;
  min-width: 60px;
  border-color: #a2a2a2;
  background-color: transparent !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.canvas-view .btn-secondary.active {
  background-color: #139dcc !important;
  border-color: #139dcc !important;
  color: #fff !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.canvas-view .btn-secondary:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.canvas-view .btn-secondary:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
  background-color: #139dcc !important;
  border-color: #139dcc !important;
  color: #fff !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff !important;
  background-color: #139dcc !important;
  border-color: #139dcc !important;
}

.zones-list li {
  background-color: transparent;
  padding: 10px;
  color: #fff;
  border-radius: 6px;
  width: 50%;
}

.zones-list li .nav-link {
  width: 100%;
}

.zones-list li:not(:last-child) {
  margin-bottom: 5px;
}

.zones-list li:hover {
  background-color: #0c83ab;
}

.zones-list li .zone-name {
  font-size: 14px;
  font-weight: 600;
}

.zones-list li div .fa {
  font-size: 20px;
  color: #fff;
}

.card .card-header {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  padding: 13px 20px;
  background-color: #d9d9d9;
}

.card .section-title {
  font-size: 16px;
  color: #139dcc;
  font-weight: 600;
}

.card .card-body label.caption {
  color: #646464;
}

.card .card-body label.value {
  color: #1e1414;
}

.card.parent > .card-body {
  overflow-y: auto;
}

.card .section-btn-panel {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.dog-bio {
  border: 3px solid #f78b56;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.dog-bio img {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.modal .modal-body label.caption {
  color: #646464;
}

.modal .modal-body label.value {
  color: #1e1414;
}

.my-pets .form-control {
  border-radius: 0px;
  font-size: 13px;
  height: 35px;
}

.my-pets .form-control::-webkit-input-placeholder {
  font-size: 13px;
}

.my-pets .form-control:-ms-input-placeholder {
  font-size: 13px;
}

.my-pets .form-control::-ms-input-placeholder {
  font-size: 13px;
}

.my-pets .form-control::placeholder {
  font-size: 13px;
}

.my-pets .bg-image-holder {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 180px;
  width: 100%;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #0983ad;
}

.my-pets .pet-info-container {
  width: 100%;
}

.my-pets .pet-info-container .pet-info-inner {
  padding: 1rem;
}

.my-pets .pet-info-container .pet-info-inner label {
  margin-bottom: 7px !important;
}

.my-pets .quick-icons .btn {
  font-size: 20px;
  color: #139DCC;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.my-pets .dog-bio {
  border: 2px solid #f78b56;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.my-pets .dog-bio img {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.my-pets .input-group,
.my-pets .dropdown__control,
.my-pets .custom-shadow {
  border: 1px solid #ced4da;
}

.my-pets.modal-body .caption-label,
.my-pets.modal-body .value-label {
  color: #000 !important;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #a9acd699 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.my-device .dog-bio {
  border: 2px solid #f78b56;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.my-device .dog-bio img {
  width: 76px;
  height: 76px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.my-device h4 {
  color: #139dcc;
  font-size: 18px;
  font-weight: 700;
}

.my-device .btn-remove {
  font-size: 20px;
  color: #139dcc;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.edit-device.modal-body,
.my-pets.modal-body {
  overflow-y: unset;
}

.table thead th {
  background-color: #ececec;
  color: #474747;
}

.table > :not(:first-child) {
  border-top: none;
}

.table .btn.btn-link {
  padding: 5px;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #139dcc !important;
}

.table .btn.btn-link:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.table .btn.btn-link .fa {
  color: #139dcc;
  font-size: 18px;
}

.btn-link {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #139dcc !important;
  border-color: #139dcc !important;
}

.form-check-input:checked :focus {
  border-color: #139dcc;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(19, 255, 104, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(19, 255, 104, 0.25);
}

.chiller-theme.toggled .page-heading {
  padding-left: 65px;
}

.chiller-theme.toggled .page-heading h2 {
  display: block;
}

.chiller-theme.toggled .page-heading.show {
  padding-left: 225px !important;
}

.page-heading {
  padding: 10px 0 10px 0;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.50); */
  position: fixed;
  width: 100%;
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#0282af), to(#149ccc));
  background-image: linear-gradient(to right, #0282af, #149ccc);
  z-index: 100;
  padding-left: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-heading h2 {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  line-height: 43px;
  margin-bottom: 0px;
}

.page-heading .btn-dark {
  background-color: #f38954;
  border-color: #f38954;
  float: left;
  margin-right: 15px;
}

.page-heading .btn-dark:hover {
  color: #fff;
  background-color: #f38954;
  border-color: #f38954;
}

.page-heading .btn-dark:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.page-heading .dropdown-toggle {
  width: 220px;
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0;
}

.page-heading .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  float: right;
  top: 50%;
  position: absolute;
  right: 13px;
}

.page-heading .user-pic {
  float: left;
  width: 45px;
  height: 41px;
  padding: 2px;
  margin-right: 15px;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
}

.page-heading .user-pic img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.page-heading .user-info {
  float: left;
  text-align: left;
  color: #fff;
}

.page-heading .user-info span {
  display: block;
  line-height: 20px;
}

.page-heading .user-info span.user-name {
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}

.page-heading .user-info span.user-role {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}

.page-heading .btn-dark:not(:disabled):not(.disabled).active,
.page-heading .btn-dark:not(:disabled):not(.disabled):active,
.page-heading .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #149ccc;
  border-color: #149ccc;
}

.page-heading .dropdown-toggle:focus,
.page-heading .btn-secondary:not(:disabled):not(.disabled).active,
.page-heading .btn-secondary:not(:disabled):not(.disabled):active,
.page-heading .show > .btn-secondary.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.page-heading .user-info > .page-heading .user-info .page-heading .user-info footer {
  padding: 10px 0;
  background-color: #041b29;
}

footer p {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff !important;
}

.dashboard .card {
  height: 300px;
  margin-bottom: 30px;
  border-radius: 0;
  -webkit-box-shadow: 1px 0 17px rgba(0, 0, 0, 0.22);
          box-shadow: 1px 0 17px rgba(0, 0, 0, 0.22);
}

.dashboard .card .card-header {
  padding: 10px;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid transparent;
}

.dashboard .card .card-body {
  padding: 0px;
}

.payment-details .payment-info h4 {
  font-size: 18px;
}

.payment-details .product-img {
  width: 100%;
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.18);
          box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.18);
}

.top-action h2 {
  color: #000;
  font-weight: 500;
  line-height: 30px;
  font-size: 16px;
}

.search-box span.deleteicon {
  overflow: hidden;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.search-box span.deleteicon span {
  position: absolute;
  display: block;
  right: 7px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #000;
  background-color: #ccc;
  font: 13px monospace;
  text-align: center;
  line-height: 1em;
  cursor: pointer;
}

.search-box span.deleteicon input {
  padding-right: 18px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #f38954;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
}

.search-box span.deleteicon input::-webkit-input-placeholder {
  font-size: 12px;
}

.search-box span.deleteicon input:-ms-input-placeholder {
  font-size: 12px;
}

.search-box span.deleteicon input::-ms-input-placeholder {
  font-size: 12px;
}

.search-box span.deleteicon input::placeholder {
  font-size: 12px;
}

.dtqzBx div:first-child {
  white-space: break-spaces !important;
}

.main-wrapper {
  padding-top: 75px;
}

.main-wrapper .btn.btn-primary {
  background-color: #f38954 !important;
  border: 1px solid #f38954;
  border-radius: 15px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.main-wrapper .btn.active, .main-wrapper .btn:hover, .main-wrapper .btn:focus {
  color: #f38954 !important;
  background-color: #fff !important;
  border: 1px solid #f38954 !important;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.main-wrapper .btn.btn-dark {
  background-color: #f38954;
  color: #fff;
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.main-wrapper .card {
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.19);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.19);
  padding: 15px 15px 15px 15px;
}

.main-wrapper .notification {
  margin-bottom: 40px;
}

.main-wrapper .notification .notification-title {
  font-size: 16px;
  font-weight: 600;
}

.main-wrapper .notification .notification-content {
  font-size: 12px;
  color: #6f6f6f;
}

.account-btn.toggle-btn {
  top: 6px !important;
}

.right-content .form-control {
  width: 130px;
  font-size: 13px;
  font-weight: 500;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.right-content .form-control::-webkit-input-placeholder {
  color: #000 !important;
  font-size: 13px;
  padding-bottom: 5px;
}

.right-content .form-control:-ms-input-placeholder {
  color: #000 !important;
  font-size: 13px;
  padding-bottom: 5px;
}

.right-content .form-control::-ms-input-placeholder {
  color: #000 !important;
  font-size: 13px;
  padding-bottom: 5px;
}

.right-content .form-control::placeholder {
  color: #000 !important;
  font-size: 13px;
  padding-bottom: 5px;
}

.right-content .fa-calendar-alt {
  color: #f38954;
}

#timepicker {
  border: none !important;
  cursor: pointer;
}

.subscription-plan .subplan {
  border-radius: 10px !important;
}

.subscription-plan .subplan:hover .subplan-box {
  background-color: #f38954;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.subscription-plan .subplan-box {
  background-color: #9a9a9a;
  color: #fff;
  border-radius: 5px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.subscription-plan .subplan-box.active, .subscription-plan .subplan-box:hover {
  background-color: #f38954;
}

.subscription-plan .subplan-box .plan-title {
  font-size: 21PX;
  font-weight: 500;
}

.subscription-plan .subplan-box .plan-price {
  font-size: 30PX;
  font-weight: 600;
}

.subscription-plan .subplan-content {
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  margin-top: -15px;
}

.subscription-plan .subplan-content li::before {
  display: inline-block;
  font-size: 12px;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #a6a6a5;
  margin-right: 15px;
}

.subscription-plan .subplan-content .list-group-item {
  border: none !important;
}

.subscription-plan .subplan-content .btn-primary {
  background-color: #fff !important;
  color: #f38954;
  border: 1px solid #f38954;
  border-radius: 18px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.subscription-plan .subplan-content .btn-primary.active, .subscription-plan .subplan-content .btn-primary:hover, .subscription-plan .subplan-content .btn-primary:focus {
  background-color: #f38954 !important;
  border: 1px solid #f38954;
  color: #fff !important;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.add-plan-form h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.settings .tab-content .card {
  height: 350px;
}

.settings .tab-content .card .card-title {
  font-size: 20px;
  font-weight: 700;
  color: #0e93c2;
}

.nav-pills .nav-link {
  color: #000;
  font-weight: 500;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  border-radius: 10px;
}

.nav-pills .nav-link.active, .nav-pills .nav-link:hover, .nav-pills .nav-link:focus,
.nav-pills .nav-link.show > .nav-link {
  background-color: #f38954 !important;
  color: #fff;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.idPHNo {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.rdt_TableRow {
  border: 1px solid #ccc !important;
  margin-bottom: 12px !important;
}

.rdt_Pagination {
  font-weight: 600 !important;
  color: #000 !important;
}

.fa .fa-pencil-square-o {
  color: #139dcc !important;
}

.card-details {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
}

.card-details label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.InputContainer {
  border-bottom: 1px solid #ccc !important;
}

.overflow-unset {
  overflow-y: unset !important;
}

.msg-green {
  color: green;
}

.msg-red {
  color: red;
}

.logout {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.18) !important;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.18) !important;
}

.btn-link {
  text-decoration: none !important;
}

.flatpickr-input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
  font-size: 12px !important;
}

.flatpickr-input:-ms-input-placeholder, .form-control:-ms-input-placeholder {
  font-size: 12px !important;
}

.flatpickr-input::-ms-input-placeholder, .form-control::-ms-input-placeholder {
  font-size: 12px !important;
}

.flatpickr-input::placeholder, .form-control::placeholder {
  font-size: 12px !important;
}

.css-1dimb5e-singleValue, .css-qbdosj-Input {
  color: #000 !important;
}

@media only screen and (max-width: 1024px) {
  .page-heading .dropdown-toggle {
    width: 110px !important;
  }
  .page-wrapper.toggled .page-content {
    padding-left: 0;
  }
  .dashboard .card {
    height: 300px;
  }
  .pet-label, .label-info-1 {
    font-size: 13px !important;
  }
  .my-device .dog-bio {
    width: 50px;
    height: 50px;
  }
  .my-device .dog-bio img {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 991px) {
  .login-img-holder {
    display: none;
  }
  .login-holder {
    background-image: url(../images/bg-image-tablet.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    padding: 50px 150px;
    min-height: 100vh;
  }
  .login-container {
    background-color: #149dcd;
  }
  #mainContent .nav-tabs .nav-item {
    width: 50% !important;
  }
  #mainContent .nav-tabs .nav-item .nav-link {
    width: 100% !important;
  }
  .search-block {
    left: 10px !important;
    right: 10px !important;
    top: 65px !important;
  }
  .search-block .pac-target-input {
    width: 220px !important;
  }
  .form-group .input-group.white .form-control,
  .form-group .form-control {
    margin-left: 0px !important;
  }
  .dropdown__placeholder .Select, .dropdown__single-value, .css-1jqq78o-placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .toggle-btn {
    padding: 4px 1px 1px 1px;
    border-radius: 6px;
    height: 35px;
    width: 35px;
    font-size: 20px;
    right: -45px;
  }
  .login-holder.d-flex.align-items-center.billing-address,
  .login-holder.d-flex.align-items-center.pet-info,
  .login-holder.d-flex.align-items-center.confirmation {
    display: block !important;
    -webkit-box-align: inherit !important;
        -ms-flex-align: inherit !important;
            align-items: inherit !important;
  }
  .my-account {
    margin: 30px auto;
  }
  .card .section-btn-panel {
    position: relative;
    bottom: auto;
    right: auto;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
    float: none !important;
  }
  .form-control.calender,
  .form-control.watch {
    background-size: 3% !important;
  }
  #settingModal .modal-body,
  #slippingModeModal .modal-body {
    height: auto !important;
  }
}

@media (max-width: 767px) {
  aside {
    display: none;
  }
  .navbar .navbar-collapse .navbar-nav li {
    padding: 0px 4px;
  }
  .accordion .accordion-body .quick-icons {
    float: right;
  }
  .mobile-list {
    border: 1px solid #fff;
    margin-top: 10px;
    color: #fff;
  }
  .mobile-list li .fa {
    margin-right: 10px;
  }
  #mainContent {
    margin-left: 0px;
  }
  #mainContent .accordion .accordion-body {
    padding: 10px !important;
  }
  #mainContent .accordion .accordion-body .dog-bio {
    border-width: 2px !important;
    width: 45px !important;
    height: 45px !important;
  }
  #mainContent .accordion .accordion-body .dog-bio img {
    width: 41px;
    height: 41px;
  }
  #mainContent .accordion .accordion-body .dog-name {
    font-size: 15px;
  }
  #mainContent.sidebar.show {
    top: 0px !important;
  }
  #mainContent .nav-tabs {
    width: 100%;
  }
  #mainContent .nav-tabs .nav-item {
    width: 50%;
  }
  #mainContent .nav-tabs .nav-item .nav-link {
    width: 100%;
  }
  #mainContent .nav-tabs .nav-item .nav-link:hover {
    color: #fff !important;
    background-color: transparent !important;
    border-color: #fff !important;
  }
  #mainContent .nav-tabs li.nav-item {
    margin-bottom: 0px;
  }
  #mainContent .nav-tabs li.nav-item .nav-link {
    padding: 10px 8px;
    font-size: 15px;
  }
  .dropdown #mainContent.show {
    margin-top: 10px !important;
  }
  #mainContent.show {
    width: calc(100%) !important;
  }
  .search-block {
    display: block !important;
    width: 80% !important;
    left: 10px !important;
    right: 10px !important;
    top: 65px !important;
  }
  .search-block input {
    width: 330px !important;
  }
  .search-block button {
    margin-left: 23%;
  }
  .sidebar {
    z-index: 10 !important;
  }
  .navbar .navbar-collapse {
    padding-top: 10px;
    height: 100vh;
    overflow-y: auto;
  }
  .navbar .navbar-toggler {
    color: #ffffff;
    border-color: #ffffff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .navbar .navbar-nav {
    border-top: 1px solid #fff;
    padding-top: 15px;
  }
  .navbar .navbar-nav li.nav-item {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .navbar .navbar-nav li.nav-item .dropdown-toggle {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    display: block !important;
    position: relative;
    height: 32px;
  }
  .navbar .navbar-nav li.nav-item .dropdown-toggle img {
    float: left;
  }
  .navbar .navbar-nav li.nav-item .dropdown-toggle span {
    float: right;
    /* justify-content: flex-end; */
    padding-top: 6px;
    max-width: 200px;
  }
  .navbar .navbar-nav li.nav-item .dropdown-toggle::after {
    float: right;
    top: 6px;
    position: absolute;
    right: 0;
  }
  .dropdown-toggle.show {
    margin-bottom: 10px;
  }
  .login-holder {
    padding: 15px;
    height: 100vh;
    background-attachment: fixed;
  }
  .login-holder .login-container {
    padding: 15px;
  }
  .login-holder .login-container .forgot-pwd {
    font-size: 13px;
  }
  .login-holder .login-container .list-inline .list-inline-item {
    width: 100%;
  }
  .login-holder .login-container .list-inline .list-inline-item .btn {
    font-size: 14px !important;
  }
  .react-confirm-alert .custom-ui {
    padding: 20px;
  }
  .react-confirm-alert .custom-ui .fa-3x {
    font-size: 30px;
  }
  .react-confirm-alert .custom-ui h3 {
    font-size: 15px;
  }
  .react-confirm-alert .custom-ui .btn-outline-primary {
    width: 110px !important;
    padding: 8px 8px !important;
  }
  .modal .advanced-settings-container .panel-container label {
    font-size: 12px;
  }
  .modal .advanced-settings-container .panel-container .btn-outline-primary {
    margin-left: 10px;
  }
  .modal .modal-footer .list-inline-item .btn-outline-primary {
    font-size: 13px !important;
  }
  .modal .modal-dialog.modal-xl {
    margin: 1rem auto;
  }
  .card.parent {
    display: inline-block;
  }
  .sidebar {
    top: 120px;
  }
  .sidebar.show {
    top: 120px;
  }
  #mainContent.show {
    margin-left: 0;
  }
  .card.parent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .page-heading h2 {
    font-size: 16px;
    line-height: 30px;
  }
  .page-heading .dropdown-toggle {
    width: 50px;
  }
  .page-heading .user-pic {
    width: 30px;
    height: 30px;
  }
  .page-heading .user-info {
    display: none;
  }
  .page-heading .dropdown-toggle::after {
    right: 0;
  }
  .page-wrapper.toggled #show-sidebar {
    left: -40px;
    margin-top: 0px;
  }
  /* .info-box {
        margin-top: 0;
    } */
  .page-wrapper.chiller-theme.toggled .page-heading h2 {
    display: none;
  }
  .content-holder {
    padding-top: 15px;
  }
  .custom-control.custom-checkbox {
    font-size: 14px;
  }
  .forgot-password {
    font-size: 12px;
  }
  .profile .card {
    border: none;
  }
  .profile .card-body {
    padding: 0px !important;
  }
  .pet-info-container .pet-info-inner .list-inline {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
}

.admin-login .login-holder {
  background-color: #fff !important;
}

.admin-login .login-holder .login-container {
  background-color: #149dcd !important;
}

@media (max-width: 480px) {
  .btn {
    font-size: 13px;
    border-radius: 6px;
    height: 34px;
  }
  .login-holder.d-flex.align-items-center.billing-address,
  .login-holder.d-flex.align-items-center.pet-info,
  .login-holder.d-flex.align-items-center.confirmation {
    display: block !important;
    -webkit-box-align: inherit !important;
        -ms-flex-align: inherit !important;
            align-items: inherit !important;
  }
  .caption-label {
    margin-bottom: 7px;
  }
  .policy-text {
    text-align: justify;
  }
  .modal .modal-footer {
    padding: 5px 10px;
  }
  .modal .modal-footer.advance-search ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0;
  }
  .modal .modal-footer.advance-search ul li {
    float: none !important;
    margin: 0 !important;
  }
  .modal-dialog {
    margin: 1.5rem 0;
  }
  #settingModal .modal-body,
  #slippingModeModal .modal-body,
  #createPowerSavingZoneModal .modal-body,
  #createSafeZoneModal .modal-body {
    height: auto !important;
  }
  #settingModal .modal-body .px-5,
  #slippingModeModal .modal-body .px-5,
  #createPowerSavingZoneModal .modal-body .px-5,
  #createSafeZoneModal .modal-body .px-5 {
    padding: 0 !important;
  }
  .modal-body.my-pets .px-5 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .btn {
    height: auto !important;
  }
  #mainContent .toggle-btn {
    height: 40px !important;
  }
  .card-body.my-device div.mb-3 {
    position: relative;
    display: block !important;
  }
  .card-body.my-device div.mb-3 .d-flex.align-items-center {
    width: 100%;
  }
  .card-body.my-device div.mb-3 .floating-btn {
    display: block !important;
    position: absolute;
    right: -15px;
    top: -15px;
    width: auto !important;
  }
  .min-width-120 {
    min-width: 100px;
  }
}

@media (max-width: 375px) {
  .modal .modal-footer .list-inline-item .btn-outline-primary {
    font-size: 10px !important;
  }
  .modal .modal-body .px-5 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.msg p {
  line-height: 34px;
  text-align: center;
}

.login-container .css-1s2u09g-control,
.login-container .css-1pahdxg-control {
  background-color: #E8F5FA !important;
  border-color: #E8F5FA !important;
  border-radius: 0px !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.css-14el2xx-placeholder {
  font-size: 12px !important;
  color: #6c757d !important;
}

.login-container .css-319lph-ValueContainer {
  padding: 2px 0px !important;
}

.login-container .css-1okebmr-indicatorSeparator {
  display: none;
}

.login-container .css-tlfecz-indicatorContainer,
.login-container.css-qc6sy-singleValue {
  font-size: 14px;
  color: #333333 !important;
}

.my-device .css-319lph-ValueContainer {
  padding-left: 5px !important;
}

.my-device .css-319lph-ValueContainer::before {
  display: none !important;
}

.my-pets .device-icon .css-319lph-ValueContainer {
  padding-left: 40px !important;
}

.my-pets .device-icon .css-319lph-ValueContainer::before {
  font-family: 'FontAwesome';
  content: '\f8d9';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.my-pets .css-319lph-ValueContainer {
  padding-left: 40px !important;
}

.my-pets .css-319lph-ValueContainer::before {
  font-family: 'FontAwesome';
  content: '\f017';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.login-container .css-1fdsijx-ValueContainer,
.login-container .css-319lph-ValueContainer,
.edit-device .css-319lph-ValueContainer {
  padding-left: 40px !important;
}

.login-container .css-1fdsijx-ValueContainer::before,
.login-container .css-319lph-ValueContainer::before,
.edit-device .css-319lph-ValueContainer::before {
  font-family: 'FontAwesome';
  content: '\f1b0';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.login-container .species .css-319lph-ValueContainer::before, .login-container .species .css-1fdsijx-ValueContainer::before,
.my-pets .species .css-319lph-ValueContainer::before,
.my-pets .species .css-1fdsijx-ValueContainer::before {
  content: '\f1b0';
}

.login-container .gender .css-319lph-ValueContainer::before, .login-container .gender .css-1fdsijx-ValueContainer::before,
.my-pets .gender .css-319lph-ValueContainer::before,
.my-pets .gender .css-1fdsijx-ValueContainer::before {
  font-family: 'FontAwesome' !important;
  content: '\f228' !important;
}

.login-container .country .css-319lph-ValueContainer::before {
  content: '\f0ac' !important;
}

.login-container .timezone .css-319lph-ValueContainer::before {
  content: '\f017' !important;
}

.timezone .css-319lph-ValueContainer {
  padding-left: 40px !important;
}

.timezone .css-319lph-ValueContainer::before {
  font-family: 'FontAwesome';
  content: '\f017';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.country .css-319lph-ValueContainer {
  padding-left: 40px !important;
}

.country .css-319lph-ValueContainer::before {
  font-family: 'FontAwesome';
  content: '\f0ac';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.my-pets .card {
  margin-bottom: 15px;
}

.my-pets .errors {
  color: #DC3545;
}

.my-pets .css-1s2u09g-control {
  border-radius: 0px !important;
}

.setting-device .errors {
  color: #DC3545;
}

.my-alerts .css-14el2xx-placeholder {
  font-size: 14px;
}

.my-alerts .css-14el2xx-placeholder .css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.my-alerts .css-14el2xx-placeholder .css-tlfecz-indicatorContainer {
  color: #000 !important;
}

.my-alerts .css-14el2xx-placeholder .css-1s2u09g-control {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.my-alerts .css-14el2xx-placeholder .css-qc6sy-singleValue {
  font-size: 14px !important;
}

._loading_overlay_wrapper {
  z-index: 1050;
}

._loading_overlay_wrapper.css-79elbk {
  position: unset;
}

.dropdown__menu,
.css-2613qy-menu {
  font-size: 14px !important;
}

.css-qc6sy-singleValue {
  font-size: 14px !important;
}

.css-14el2xx-placeholder {
  font-size: 14px;
}

.my-device .table {
  font-size: 14px;
}

.my-device .table thead th {
  border-bottom: 2px solid #fff !important;
}

.my-device .table tr {
  border-color: #fff !important;
}

#primary_color {
  height: 40px;
  max-width: 80px;
  border: none;
  outline: none;
  -webkit-appearance: none;
}

#primary_color::-webkit-color-swatch-wrapper {
  padding: 0;
}

#primary_color::-webkit-color-swatch {
  border: none;
}

.spinner-border {
  margin-right: 5px !important;
  width: 17px !important;
  height: 17px !important;
}

.btn-bg {
  background-color: #f78b56 !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}

.icon-btn {
  background-color: transparent;
  border: none;
}

.toggle {
  --width: 80px;
  --height: calc(var(--width) / 3);
  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle input:checked + .slider {
  border-color: #139dcc;
}

.toggle input:checked + .slider::before {
  border-color: #139dcc;
  background-color: #139dcc;
  -webkit-transform: translateX(calc(var(--width) - var(--height)));
          transform: translateX(calc(var(--width) - var(--height)));
}

.toggle input:checked ~ .labels::after {
  opacity: 0;
}

.toggle input:checked ~ .labels::before {
  opacity: 1;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #969696;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 24px;
  /* height: 20px; */
  border-radius: 0px;
  border: 3px solid #969696;
  background-color: #969696;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.toggle .labels {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  color: #4d4d4d;
  font-size: 12px;
  font-family: sans-serif;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 5px;
  bottom: 12px;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 7px;
  bottom: 12px;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

.custom-ui {
  text-align: center;
  border-radius: 4px;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  -webkit-box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
          box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui h1 {
  margin-top: 0;
}

.custom-ui .btn-outline-primary {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff !important;
  border-radius: 4px;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff !important;
  font-size: 14px;
}

.custom-ui .btn-outline-primary:hover {
  border: 1px solid #f38954 !important;
}

.data-label {
  font-size: 13px;
}

.icon-color .dropdown__value-container {
  padding-left: 40px;
}

.icon-color .dropdown__value-container::before,
.icon-color .dropdown__value-container .css-1fdsijx-ValueContainer::before,
.icon-color .css-319lph-ValueContainer::before {
  font-family: "FontAwesome";
  content: "\f111" !important;
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.calender .css-319lph-ValueContainer::before {
  font-family: "FontAwesome";
  content: "\f073" !important;
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.modal .modal-body .nav-tabs .nav-item .nav-link {
  color: #000 !important;
  border: 1px solid #e7e4e4;
  padding: 8px;
  font-size: 14px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.modal .modal-body .nav-tabs .nav-item .nav-link.active, .modal .modal-body .nav-tabs .nav-item .nav-link:hover {
  background-color: #f38954 !important;
  color: #fff !important;
  border-color: #f38954 !important;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.modal .modal-body .tab-content .tab-pane .file-upload p {
  font-size: 15px;
}

.modal .modal-body .tab-content .file-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.view-structure .nav-tabs .nav-item .btn-primary {
  margin-right: 10px;
  font-size: 13px;
}

.view-structure .nav-tabs .nav-item .icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  height: 25px;
  width: 20px;
}

.view-structure .nav-tabs .nav-item .icon.icon-list {
  background-image: url(../images/list_black.png);
}

.view-structure .nav-tabs .nav-item .icon.icon-list:hover, .view-structure .nav-tabs .nav-item .icon.icon-list.active, .view-structure .nav-tabs .nav-item .icon.icon-list:focus {
  background-image: url(../images/list_o.png);
}

.view-structure .nav-tabs .nav-item .icon.icon-grid {
  background-image: url(../images/grid_b.png);
}

.view-structure .nav-tabs .nav-item .icon.icon-grid:hover, .view-structure .nav-tabs .nav-item .icon.icon-grid.active, .view-structure .nav-tabs .nav-item .icon.icon-grid:focus {
  background-image: url(../images/grid_o.png);
}

.view-structure .nav-tabs .nav-link {
  border: none;
  border: 1px solid #000 !important;
  border-radius: 5px !important;
  padding: 5px 10px;
  margin-right: 10px;
}

.view-structure .nav-tabs .nav-link:hover, .view-structure .nav-tabs .nav-link.active {
  color: #f38954 !important;
  border: 1px solid #f38954 !important;
}

.view-structure .tab-content {
  clear: both;
}

.instruction {
  font-size: 14px;
}

.instruction-title {
  cursor: pointer;
  font-size: 15px;
}

.timezone .css-1fdsijx-ValueContainer {
  padding-left: 40px !important;
}

.timezone .css-1fdsijx-ValueContainer::before {
  font-family: 'FontAwesome';
  content: '\f017';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.gender .css-319lph-ValueContainer, .gender .css-1fdsijx-ValueContainer {
  padding-left: 40px;
}

.gender .css-319lph-ValueContainer::before, .gender .css-1fdsijx-ValueContainer::before {
  font-family: 'FontAwesome' !important;
  content: '\f228' !important;
  font-family: 'FontAwesome';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.species .css-319lph-ValueContainer, .species .css-1fdsijx-ValueContainer {
  padding-left: 40px;
}

.species .css-319lph-ValueContainer::before, .species .css-1fdsijx-ValueContainer::before {
  font-family: 'FontAwesome' !important;
  content: '\f1b0' !important;
  font-family: 'FontAwesome';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.device-icon .css-1fdsijx-ValueContainer {
  padding-left: 40px !important;
}

.device-icon .css-1fdsijx-ValueContainer::before {
  font-family: 'FontAwesome';
  content: '\f8d9';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.country .css-1fdsijx-ValueContainer {
  padding-left: 40px !important;
}

.country .css-1fdsijx-ValueContainer::before {
  font-family: 'FontAwesome';
  content: '\f0ac';
  position: absolute;
  left: 15px;
  color: #149dcd !important;
  font-size: 16px;
}

.dropdown__placeholder .Select, .dropdown__single-value, .css-1jqq78o-placeholder {
  font-size: 12px !important;
}

.dropdown__single-value {
  font-size: 13px !important;
  margin-left: 24px !important;
}

.infoBox {
  width: auto !important;
}

.navbar-toggler:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
}

.history-section .accordion-header,
.event-section .accordion-header {
  border-bottom: none !important;
}

.css-1dimb5e-singleValue, .css-qbdosj-Input {
  font-size: 13px;
}

._loading_overlay_overlay {
  z-index: 1200 !important;
}

.add-new-pet .modal-body #actual-btn {
  margin-top: 15px;
}

.note-box {
  padding: 22px 0px 10px 0px;
  border-radius: 10px;
  border: 1px solid;
  margin-top: -20px;
}

.note-box p {
  font-size: 14px;
}

.note-box span {
  font-size: 13px;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.set-dropdown-icon {
  position: relative;
}

.set-dropdown-icon .adjust-icon {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 1;
}

.set-dropdown-icon .css-1fdsijx-ValueContainer,
.set-dropdown-icon .dropdown__value-container--has-value {
  padding-left: 35px !important;
}
