.accordion-header .accordion-button {
    padding: 8px;
    font-weight: bold;
}

.accordion-header .accordion-button::after {
    margin-top: -8px;
}

.width100 {
    width: 100%
}

.justify-space-around {
    justify-content: space-around !important;
}

.shift-left {
    margin-left: -20px;
}

.command-in-progress {
    color: #fff !important;
    background-color: #f78b56 !important;
    border-color: #f78b56 !important;
    font-size: 12px;
    padding: 2px;
    border-radius: 3px;
    margin-right: 4px;
    width: min-content;
    min-width: 150px;
    margin-top: -5px;
}

.no-device{
    color: #fff !important;
    font-size: 12px;
    margin-right: 3px;
    min-width: 84px;
    margin-bottom: 0;
}

.safe-zone-label {
    color: #000;
    font-size: 12px;
    margin-left: 16px;
}

.bottom-4 {
    margin-bottom: 4px;
}
