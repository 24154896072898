
.alert-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.alert-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 200px;
    margin-top: 6px;
}

.alert-box {
    margin-right: 4px;
}

.interval-label {
    height: 40px;
}

.panel-container {
    padding: 4px;
}

.panel-container:first-child {
    border-right: thin solid darkgray;
    padding: 4px;
    padding-right: 8px;
    margin-bottom: 10px;
}

.black-text {
    color: black !important;
}
@media (max-width: 767px) {
    .alert-container {
        justify-content: space-around;
}
    .alert-item{
        justify-content: space-between;
}
}
